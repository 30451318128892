import React,{useEffect,useState, useRef,memo} from "react";
import { useHistory } from "react-router";
import {Card,CircularProgress,
    ListItem, TextField,useMediaQuery,Collapse,IconButton,Modal} from '@material-ui/core';
import {fetchGet,fetchPost} from "./AuthenticatedFetch";
import Button from '@material-ui/core/Button';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HelpIcon from '@material-ui/icons/Help';
import CheckIcon from '@material-ui/icons/Check';
import FilterListIcon from '@material-ui/icons/FilterList';
import ErrorMessageWidget from "./ErrorMessageWidget";
import EditableListItemWidget from "./editable-list-item-widget";
import AsyncBoxWidget from "./AsyncBoxWidget";
import firebase from "./Firebase";
import {Skeleton} from "@material-ui/lab"
import { Cancel } from "@material-ui/icons";
import SortableList from "./SortableList";
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import memoize from 'memoize-one';
import InfiniteLoader from "react-window-infinite-loader";


const useStyles = makeStyles(theme => ({
    mainBox:{
        display:"flex",
        flex:"2",
        flexDirection:"column",
        alignItems:"flex-start",
        justifyContent:"center",
        fontFamily: "'Prompt', sans-serif"
    },
    btn: {
        textTransform: "none"
    },
    loadingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"column",
      width:"100%",
      flex:"2",
      fontFamily: "'Prompt', sans-serif",
    },
    loadingSpinner:{
      width:"2em!important",
      height:"2em!important"
    },
    message:{
      margin:"0.6em 0.5em",
      fontSize:"1.2em",
      
    },
    errorBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      flex:"2",
      fontFamily: "'Prompt', sans-serif"
      },
      newPubBtn:{
        width:"20em",
        height:"5em",
        backgroundColor:"#4db6ac",
        color: "white",
        margin:"1em"
      },
      publicationsBtn:{
        width:"20em",
        height:"5em",
        backgroundColor:"#64b5f6",
        color: "white",
        margin:"1em"
      },
      titleBox:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        width:"100%"
      },
      titleBoxIcon:{
          width:"1.2em",
          height:"1.2em",
          color:"#546e7a",
          marginRight:"0.5em"
      },
      title: {
        fontFamily: "'Prompt', sans-serif",
        color:"#546e7a",
        fontSize:"1.2em",
        margin:"0",
        flex:"2"
      },
      postCounterBox: {
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center"
      },
      postCounterText: {
        fontFamily: "'Prompt', sans-serif",
        color:"#4db6ac",
        margin:"0",
      },
      postCounterHelpIcon:{
            width:"0.8em",
            height:"0.8em",
            color:"#1565c0",
            marginLeft:"0.5em",
            boxShadow:"1px 1px 4px rgba(200,200,200,0.8)",
            borderRadius:"50%"
      },
      contentBox: {
        display:"flex",
        flexDirection:"column",
        width:"99%",
        flex:"2",
        alignItems:"center",
        justifyContent:"flex-start",
        overflowY:"auto"
      },
      list:{
          width:"100%"
      },
      listItemCard:{
        flex:"2",
        padding:"0 0.5em 0.5em 0.5em",
        height:"100%"
      },
      itemDataBox:{
        display:"flex",
        flexDirection:"column",
        width:"100%",
        flex:"2",
        alignItems:"flex-start",
        justifyContent:"center"
      },
      itemDataState:{
          padding:"0.3em",
        borderRadius:"0.2em",
        color:"white",
        fontWeight:"bold"
      },
      itemDescription:{
        color:"#546e7a",
        fontSize:"1em",
        margin:"0.5em 0",
        flex:"2",
        wordBreak:"break-word"
      },
      itemInnerDataBox:{
        display:"flex",
        flexDirection:"column",
        width:"100%",
        flex:"2",
        alignItems:"center",
        justifyContent:"center",
        margin:"0.5em 0 1em 0",
        wordBreak:"break-all"
      },
      itemSecondDataBox:{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"flex-start"
      },
      itemPrice:{
          color:"#546e7a",
          fontWeight:"bold",
          fontSize:"1.3em",
          margin:"0 0.5em 0 0"
      },
      stateReasonBtn:{
          textTransform:"none",
          marginLeft:"0.5em",
          color:"#607d8b"
      },
      resetExpirationBtn:{
        textTransform:"none",
        marginLeft:"0.5em",
        color:"white",
        backgroundColor:"#03a9f4",
        "&:hover": {
            backgroundColor: "#0288d1"
        }
      },
      reasonMessageBox:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
      },
      reasonMessageText:{
        color:"#546e7a",
        fontSize:"1em",
        margin:"0.5em 0",
        flex:"2",
        wordBreak:"break-word",
        padding:"1em",
        margin:"1em 0",
        backgroundColor:"#fff9c4",
        borderRadius:"0.3em",
        textAlign:"justify"
      },
      reasonMessageActionsBox:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
      },
      reasonMsgAcceptBtn:{
        textTransform:"none",
        marginLeft:"0.5em",
      },
      reasonMsgCloseBtn:{
        textTransform:"none",
        margin:"1em",
      },
      reasonLoadingBox:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
      },
      reasonInnerBox:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        minHeight:"13em"
      },
      reasonSuccessIcon:{
        width:"0.8em",
        height:"0.8em",
        color:"#4caf50",
        margin:"0.5em 0"
    },
    reasonSuccessText:{
        color:"#4caf50",
        margin:"0.5em 0",
        textAlign:"center",
        wordBreak:"break-word"
    },
    listItem:{
        // height:"14em",
        padding:"0.5em 0.2em",
        height:"100%"
    },
    flexColumn:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
    },
    flexRow:{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"flex-start"
    },
    userNameCounter:{
        margin:"0",
        flex:"2"
    },
    userMetaDataBox:{
        border: "1px solid rgb(220,220,220)",
        margin:"1em 0",
        borderRadius:"0.3em"
    },
    userMetaDataInnerBox:{
        padding:"0.5em"
    },
    itemUser:{
        margin:"0.5em",
        color:"#546e7a"
    },
    itemPrimaryBtn:{
        textTransform:"none",
        marginLeft:"0.5em",
        color:"white",
        backgroundColor:"#039be5"
    },
    itemSuccessBtn:{
        textTransform:"none",
        marginLeft:"0.5em",
        color:"white",
        backgroundColor:"#43a047"
    },
    itemDangerBtn:{
        textTransform:"none",
        marginLeft:"0.5em",
        color:"white",
        backgroundColor:"#e53935"
    },
    rejectionReasonField: {
        width:"20em",
        maxWidth:"90%"
    },
    roundedBtn:{
        textTransform:"none",
        marginLeft:"0.5em",
        borderRadius:"2em",
        padding:"0.3em 1em"
    },
    imageBox:{
        display:"flex",
        flexDirection:"column",
        height:"20em",
        flex:"2",
        alignItems:"center",
        justifyContent:"center"
    },
    itemBox:{
        display:"grid",
        width:"100%",
        flex:"2",
        gridTemplateColumns: "20em 1fr",
        gridRow: "1",
        gridGap: "1em"
    },
    shadow:{
        boxShadow:"1px 1px 2px rgba(150,150,150,0.8)"
    },
    filterBtn:{
                    
    },
    filterIcon: {
        color:"#607d8b"
    },
    filterBox:{
        display:"flex",
        flexDirection:"column",
        padding:"0.5em",
        width:"100%",
        flex:"2",
        alignItems:"center",
        justifyContent:"center",
    },
    lightGreyRounderBorder:{
        border: "1px solid #b0bec5",
        borderRadius:"0.3em"
    },
    filterContentBox:{
        display:"flex",
        flexDirection:"column",
        flex:"2",
        alignItems:"center",
        justifyContent:"center"
    },
    filterActionBox:{
        display:"flex",
        padding:"0.5em",
        alignItems:"center",
        justifyContent:"center"
    },
    filterActionBtn:{
        backgroundColor:"#03a9f4",
        color:"white",
        "&:hover": {
            backgroundColor: "#0288d1"
        }
    },
    storeLink:{
        color:"white",
        fontSize:"1em",
        padding:"0.3em 1em",
        backgroundColor:"#00bcd4",
        borderRadius:"2em",   
        textDecoration:"none",
        boxShadow: "2px 2px 2px rgba(200,200,200,0.8)",
        margin:"0.5em 0",
        wordBreak:"break-word"
      }
    
  }));

let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}
  
//   renderRow.propTypes = {
//     index: PropTypes.number.isRequired,
//     style: PropTypes.object.isRequired,
//   };

const Row = memo(({ data, index, style }) => {
 
    // Data passed to List as "itemData" is available as props.data
    const { items,imageURLs,deletePublication,showReasonMessage,askResetPubExpiration,
        askConfirmation,openRejectForm,goToEditPublication,appUserInfo,
        userInfo,theme,uiStates,isItemLoaded,baseStoreURL } = data;
    const pub = items[index];
    const classes = useStyles();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const getStyleByState = state => {
        // pub.state === "pending" ? "#ffc107" : 
        //                                         (pub.state === "approved" ? "#4caf50" : "#f44336"
        switch(state){
            case "approved": return "#4caf50"; break;
            case "pending": return "#ffc107"; break;
            case "rejected": return "#f44336";break;
            case "expired": return "#9e9e9e"; break;
        }
    }

    const getStateLabel = state => {
        // pub.state === "pending" ? "#ffc107" : 
        //                                         (pub.state === "approved" ? "#4caf50" : "#f44336"
        switch(state){
            case "approved": return "Aprobada"; break;
            case "pending": return "Pendiente"; break;
            case "rejected": return "Rechazada";break;
            case "expired": return "Expirada"; break;
        }
    }

    if(!isItemLoaded(index)){
        return (
            
            <div style={style} key={index}>
                 
                <ListItem className={classes.listItem}>
                    <Card className={classes.listItemCard} >
                        <p>Cargando...</p>
                    </Card>
                </ListItem>
            </div>
        )
    }else{

        return (
            <div style={style} key={index}>
                <ListItem className={classes.listItem}>
                    <Card className={classes.listItemCard} >
    
                        <EditableListItemWidget
    
                            className={classes.listItem}
                            item={pub}
                            deletable={true}
                            editable={true}
                            deletingMessage="Borrando publicación"
                            errorDeleteTitle="Ocurrió un error al borrar la publicación"
                            deleteMessage="¿Borrar la publicación?"
                            confirmBtnText="Borrar"
                            cancelBtnText="Cancelar"
                            onConfirmDeletion={e => deletePublication(pub,e)}
                            onEdit={goToEditPublication}
                        >
                        
                            <div className={classes.itemBox} style={{
                                    gridTemplateRows: smallScreen ? "20em 1fr" : "1fr",
                                    gridTemplateColumns: smallScreen ? "1fr" : "20em 1fr"
                                }}>
    
                                    <div className={classes.imageBox}>
                                        
                                        {
                                            imageURLs[pub.id] && imageURLs[pub.id].status === "pending" ?
                                            (<Skeleton className={classes.shadow} variant="rect" width="100%" height="20em" />)
                                            :
                                            (<img className={classes.shadow} width={smallScreen ? "auto" : "100%"} height={smallScreen ? "100%" : "auto"} src={imageURLs[pub.id] && 
                                                imageURLs[pub.id].status === "fulfilled" ? imageURLs[pub.id].value : null} /> )
                                        }
                                    </div>
    
                                    <div className={classes.itemInnerDataBox}>
                                        <div className={classes.itemInnerDataBox}>
                                            <p >{pub.name}</p>
                                            <p className={classes.itemDescription}>{pub.description}</p>
                                            <a className={classes.itemInstagramLink} href={pub.instagram_link}>{pub.instagram_link}</a>
                                            {
                                                !userInfo && pub.userInfo ?
                                                (
                                                    <p className={classes.itemUser} >
                                                        {pub.userInfo.displayName ? pub.userInfo.displayName: pub.userInfo.email}
                                                    </p>
                                                )
                                                :
                                                (
                                                    <div/>
                                                )
                                            }

                                            {
                                                pub.state === "approved" && pub.store_data ? 
                                                (
                                                    <a target="_blank" className={classes.storeLink} href={ pub.store_data ? baseStoreURL.replace("__prod_id__",pub.store_data.product_id) : "__product_id_not_found__" }>Ver en la tienda</a>
                                                )
                                                :
                                                (
                                                    <div/>
                                                )
                                            }
                                            
                                        </div>
                                        <div className={classes.itemSecondDataBox}>
                                            <div className={classes.itemDataState}
                                            style={{
                                                backgroundColor: getStyleByState(pub.state)
                                            }}>
                                                <p style={{margin:"0"}}>{getStateLabel(pub.state)
                                                }</p>
                                            </div>
                                            {
                                                pub.state === "rejected" ?
                                                (
                                                    <Button size="small"  color="default" 
                                                    classes={{
                                                        root: `${classes.stateReasonBtn} ${classes.roundedBtn}`
                                                    }}
                                                    onClick={e => showReasonMessage(pub,e)}>Ver motivo</Button>
                                                )
                                                :
                                                (<div/>)
                                                
                                            }

                                            {
                                                pub.state === "expired" ?
                                                (
                                                    <Button size="small"  color="default" variant="contained"
                                                    classes={{
                                                        root: `${classes.resetExpirationBtn} ${classes.roundedBtn}`
                                                    }}
                                                    onClick={e => askResetPubExpiration(pub,e)}>Reactivar</Button>
                                                )
                                                :
                                                (<div/>)
                                                
                                            }

                                            <span style={{
                                                flex:"2"
                                            }} />
                                            
                                            <p className={classes.itemPrice}>${pub.price}</p>
                                        </div>
                                    </div>
    
    
    
                            </div>
    
    
    
                            <div className={classes.itemDataBox} style={{
                                display: appUserInfo.role === "admin" ? "flex" : "none"
                            }}>
                                <div className={classes.flexRow} style={{
                                                justifyContent: smallScreen ? "space-around" : "flex-end"
                                            }}>
    
                                    {
                                        pub.state !== "approved" ?
                                        (
                                            <Button size="small" variant="contained" 
                                                
                                                classes={{
                                                    root: `${classes.itemSuccessBtn} ${classes.roundedBtn}`
                                                }} 
                                                style={{
                                                    margin:"1em"
                                                }}
                                                onClick={e => askConfirmation(pub,e)}>Aprobar</Button>
    
                                        )
                                        :
                                        (<div/>)
                                        
                                    }
    
                                    {
                                        pub.state !== "rejected" ? 
                                        (
                                            <Button size="small" variant="contained" 
                                            
                                                classes={{
                                                    root: `${classes.itemDangerBtn} ${classes.roundedBtn}`
                                                }} 
                                                style={{
                                                    margin:"1em"
                                                }}
                                                onClick={e => openRejectForm(pub,e)}>Rechazar</Button>
                                        )
                                        :
                                        (
                                            <div/>
                                        )
                                    }
    
                                </div>
                                
                            </div>
    
    
                        </EditableListItemWidget>
    
    
                    </Card>
                </ListItem>
            </div>
        );

    }
        

    }, areEqual);


let listener ;

const PublicationsManagerView = (props)=> {
    const classes = useStyles();
    const [loading,setLoading] = useState(true);
    const [queryObj,setQueryObj] = useState(null);
    const [errorMessage,setErrorMessage] = useState(null);
    const [publications,setPublications] = useState([]);

    const [filteredPubs,setFilteredPubs] = useState([]);
    const theme = useTheme();

    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [modalOpened,setModalOpened] = useState(false);

    const [selectedItem,setSelectedItem] = useState(null);

    const fbStorage = firebase.storage().ref();
    const [imageURLs,setImageURLs] = useState({});

    const [categories,setCategories] = useState(null);

    const [userInfo,setUserInfo] = useState(null);

    const [appUserInfo,setAppUserInfo] = useState(null);

    const [userQuotas,setUserQuotas] = useState(null);

    const [filterFormVisible,setFilterFormVisible] = useState(false);
    const [filterIndicator,setFilterIndicator] = useState(null);

    const [dbDocs,setDBDocs] = useState(null);

    const [shopMode,setShopMode] = useState("shop");

    const [pendingNotifications,setPendingNotifications] = useState(props.pendingNotifications);


    const [pubStates,setPubStates] = useState([
        {
            id: 1,value:"pending", text: "Pendiente", enabled: true
        },
        {
        
        id: 0, value:"rejected", text: "Rechazada", enabled: true
    },
    
    {
        id: 2,value:"approved", text: "Aprobada", enabled: true 
    },

    {
        id: 3,value:"expired", text: "Expirada", enabled: true 
    }]);

    const [uiStates,setUIStates] = useState({});

    const [lastSegmentDoc,setLastSegmentDoc] = useState(null);

    const [newFetch,setNewFetch] = useState(false);

    const [isNextPageLoading,setIsNextPageLoading] = useState(false);

    const [hasNextPage,setHasNextPage] = useState(true);

    const itemsLimit = 20;

    const itemsToLoad = 15;

    const [pageNumber,setPageNumber] = useState(1);

    const [baseStoreURL,setBaseStoreURL] = useState(null);

    const getModalStyle =  () =>  {
        const top = 50 ;
        const left = 50 ;
        
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = React.useState(getModalStyle);

    const clearPubNotifications = async () => {
        await props.clearPubNotifications();
        return true;
    }

    useEffect(() => {
        if(pendingNotifications){
            clearPubNotifications().then(() => {
                console.debug("Publication notifications cleared");
            }).catch(err => {
                console.error("Error when clearing pub notifications",err);
            });
        }
    },[pendingNotifications]);


    const history = useHistory();

    const serialize = obj => {
        let str = [];
        for (let p in obj)
          if (obj[p] && obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
    }

    // const getPublications = async (userId,searchConfig)=> {
        
    //     let url = window.location.origin+"/api/"+shopMode+"/publications";
    //     if(userId || searchConfig){
    //         url = url + "?";
    //     }

    //     let queryString = "";

    //     if(searchConfig){
    //         searchConfig.userId = userId;
    //         queryString = serialize(searchConfig);
    //     }else{
    //         queryString = serialize({
    //             userId : userId
    //         });
    //     }

    //     let response = await fetchGet(url+queryString);
        
    //     if(response.ok){
            
    //         let result = await response.json();

    //         return result;
    //     }else{
    //         let error = await response.text();
    //         throw error;
    //     }

    // }

    const updateList = async pubs => {
        console.debug("updating list",pubs);

        // let filtered = applyListFilter(pubs);
        // setFilteredPubs(filtered);


        
        //await loadPubsImages(filtered);
        await loadPubsImages(pubs);
        // for(let i=0; i < pubs.length; i++){
        //     if()
        // }   
        
        return true;
    }

    useEffect(() => {
        if(newFetch){
            
            if(listener){
                console.debug("publications realtime listener was already defined...remove this before proceeding");
                listener();
            }

            let userId = null;

            if( queryObj && queryObj.userId ){
                userId = queryObj.userId;
            }else if(props.userInfo.extraData.role !== "admin"){
                userId = props.userInfo.uid;
            }
           
            getPublications(userId,queryObj).then(res => {
                listener = res;
                console.debug("New publications realtime listener set");
                //setNewFetch(false);


            }).catch(err => {
                console.error("Error getting publications:",err);
            });
        }
        

    },[newFetch]);


    useEffect(() => {

        return () => {
            if(listener){
                
                listener();
                console.debug("getPublications realtime listener removed");
            }
        }
        

    },[]);


    useEffect(() => {
        
        if(dbDocs){
            let pubs = [];
           
            dbDocs.forEach( doc => {
                pubs.push({
                    id:doc.id,
                    ...doc.data()
                });

                uiStates[doc.id] = {
                    showRejectForm: false,
                    processing:false,
                    errorMessage:null,
                    success:false,
                    dialogTitle:""
                }
            });
           
            setUIStates({
                ...uiStates
            })
            // let docs = dbDocs.docs;
            let lastDoc = dbDocs.docs[dbDocs.docs.length - 1];
           
            setLastSegmentDoc(lastDoc);

            console.log(pubStates);

            let newPubs = orderByStates(pubs,pubStates);
            setFilteredPubs(newPubs);
            
            updateList(newPubs);
            setNewFetch(false);
            setLoading(false); 
            setIsNextPageLoading(false);
        }
        

    },[dbDocs]);

    const getPublications = async (userId,searchConfig) => {
        console.debug("starting getPublications api ");
        //let items = await this.databaseAPI.getAllItems("user_shop_items",["userId"]);
        // let fields = [];        

        // if(!lastSegmentDoc){
        //     console.log("no lastSegmentDoc");
           
        //     if(userId){
        //         segment = firebase.firestore().collection("user_shop_items").where("userId","==",userId).orderBy("date_state_last_changed","desc").limit(itemsLimit);
        //     }else{
        //         segment = firebase.firestore().collection("user_shop_items").orderBy("date_state_last_changed","desc").limit(itemsLimit);
        //     }
            

        //     //console.log(segment);

        // }else{
        //     console.log("yes lastSegmentDoc");
        //     console.log(lastSegmentDoc);

        //     if(userId){
        //         segment = firebase.firestore().collection("user_shop_items").where("userId","==",userId).orderBy("date_state_last_changed","desc")
        //         .startAfter(lastSegmentDoc).limit(itemsLimit);
        //     }else{
        //         segment = firebase.firestore().collection("user_shop_items").orderBy("date_state_last_changed","desc")
        //         .startAfter(lastSegmentDoc).limit(itemsLimit); 
        //     }
            

        // }
        

        // if(userId){
        //     segment = firebase.firestore().collection("user_shop_items").where("userId","==",userId).orderBy("date_state_last_changed","desc").limit(itemsLimit*pageNumber);
        // }else{
        //     segment = firebase.firestore().collection("user_shop_items").orderBy("date_state_last_changed","desc").limit(itemsLimit*pageNumber);
        // }
        let segment = firebase.firestore().collection("user_shop_items");

        if(userId){
            segment = segment.where("userId","==",userId);
        }



        if(searchConfig){
            Object.keys(searchConfig).forEach(e => {
                segment = segment.where(e,"==",searchConfig[e])
            });
        }

        if(pubStates && pubStates.length > 0){
            let states = [];
            pubStates.forEach(e => {
                states.push(e.value);
            });

            segment = segment.where("state","in",states);
        }

        segment = segment.orderBy("date_state_last_changed","desc").limit(itemsLimit*pageNumber);

            let segListener = segment.onSnapshot( querySnap =>  {

                setDBDocs(querySnap);
                let hasAnotherPage = true;
                if(querySnap.size < (itemsLimit*pageNumber)){
                    hasAnotherPage = false;
                }
                setHasNextPage(hasAnotherPage);
                if(hasAnotherPage){
                   
                    setPageNumber(pageNumber + 1);
                }
                // let currCount = hasAnotherPage ? querySnap.size + 1 : querySnap.size ;
                // setCurrItemCount(currCount);
    
                // querySnap.docChanges().forEach( change => {
                //     if (change.type === "added") {
                //         console.log("pub added ",change.doc.data())
                //     }
                //     if (change.type === "modified") {
                //         console.log("pub modified ",change.doc.data())
                //     }
                //     if (change.type === "removed") {
                //         console.log("pub removed ",change.doc.data())
                        
                //     }
                // });
                
    
    
            }, (error) => {
                console.error("Error querying FS ", error);
            });


        // if(!userId){
        //     let userInfo = {};

        //     /* eslint-disable no-await-in-loop */
        //     for(let i=0; i < items.length; i++){

        //         if(!userInfo[items[i].userId]){
        //             let us = await this.userAPI.getUserAuthInfo(items[i].userId);
        //             userInfo[items[i].userId] = {
        //                 displayName: us.displayName,
        //                 email: us.email
        //             };
        //             items[i].userInfo = userInfo[items[i].userId];
        //         }else{
        //             items[i].userInfo = userInfo[items[i].userId];
        //         }

        //     }

        // }
        
        return segListener;    
    }

    const getPublicationData = async (searchConfig)=> {
        
        let url = window.location.origin+"/api/"+shopMode+"/publication";

        let queryString = "";

        if(searchConfig){
            url = url + "?";
            queryString = serialize(searchConfig);
        }

        let response = await fetchGet(url+queryString);
        
        if(response.ok){
            
            let result = await response.json();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }

    }

    const getUserInfo = async (userId)=> {
        
        let url = window.location.origin+"/api/"+shopMode+"/userinfo?user_id="+userId;
        
        if(appUserInfo.role !== "admin"){
            url = window.location.origin+"/api/users/info";
        }

        let response = await fetchGet(url);
        
        if(response.ok){
            
            let result = await response.json();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }
    }

    const getCategories = async ()=> {
        let response = await fetchGet(window.location.origin+"/api/prestashop/categories/all");
        
        if(response.ok){
            
            let result = await response.json();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }
    }

    const getUserQuotas = async (userId)=> {
        let response = await fetchGet(window.location.origin+"/api/"+shopMode+"/userquotas?user_id="+userId);
       
        if(response.ok){
            let result = await response.json();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }

    const sendChangeStateRequest = async (userId,pubId,state,reason) => {
        let response = await fetchPost(window.location.origin+"/api/"+shopMode+"/changestate",{
            user_id: userId,
            pub_id:pubId,
            state:state,
            state_reason:reason
        });
       
        if(response.ok){
            let result = await response.text();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }

    const orderByStates = (pubs,states) => {
        let newPubs = [];

        for(let x=0; x < states.length; x++){
            for(let i=0 ; i < pubs.length; i++){
                if(pubs[i].state === states[x].value && states[x].enabled ){
                    newPubs.push({
                        ...pubs[i]
                    });
                }
            }
        }

        return newPubs;
       
    }

    const applyListFilter = pubs => {      
        // let newPubs = orderByStates(pubs,pubStates);
        // setFilteredPubs(newPubs);

        // return newPubs;
        setQueryObj(null);
        setLastSegmentDoc(null);
        setPageNumber(1);
        setNewFetch(true);

        return true;

    }


    const getStates = pubStates => {
        let states = [];
        pubStates.forEach(e => {
            if(e.enabled)
            states.push(e.text);
        });

        return states.join(", ");
    }

    const getStoreBaseURL = async ()=> {
        let response = await fetchGet(window.location.origin+"/api/usershop/productbaseurl");
       
        if(response.ok){
            let result = await response.text();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }


    const loadPublications = async (searchConfig) => {
        try{
            setLoading(true);
            setErrorMessage(null);

          

            setUserInfo(null);
            setUserQuotas(null);

            // //let catsProm = getCategories();
            // let itemsProm = getPublications(null,searchConfig);

            // let results = await Promise.all([itemsProm]);
            // //setCategories(results[0]);
            // for(let i=0; i< results[0].length; i++){
            //     uiStates[results[0][i].id] = {
            //         showRejectForm: false,
            //         processing:false,
            //         errorMessage:null,
            //         success:false,
            //         dialogTitle:""
            //     }
            // }

            let sts = getStates(pubStates);
            setFilterIndicator(sts);

            setNewFetch(true);

            let storeBaseURL = await getStoreBaseURL();
            setBaseStoreURL(storeBaseURL);
            
            //setPublications(results[0]);

            //let filtered = applyListFilter(results[0]);

           
            // setFilteredPubs(results[1]); 

            //await loadPubsImages(filtered);

            return true;

        }catch(err){
            setLoading(false);
            setErrorMessage(err.toString());
            console.error(err);
        }
    }

    const loadUserPublications = async (searchConfig) => {
        try{
            setLoading(true);
            setErrorMessage(null); 


           
            //let catsProm = getCategories();
            //let itemsProm = getPublications(searchConfig.userId,searchConfig);

            if(appUserInfo.role !== "admin"){
                
                setUserInfo(appUserInfo);

                let usQtas = props.userInfo.extraData ? props.userInfo.extraData.quotas : null;

                setUserQuotas({
                    ...usQtas
                });

            }else{
                let usProm = getUserInfo(searchConfig.userId);
                let usQProm = getUserQuotas(searchConfig.userId);
                let results = await Promise.all([usProm,usQProm]);
                setUserInfo(results[0]);
                setUserQuotas(results[1]);
            }

            let storeBaseURL = await getStoreBaseURL();
            setBaseStoreURL(storeBaseURL);

            // if((queryObj && !queryObj.userId) || (queryObj && queryObj.userId !== appUserInfo.uid)){

            //     let usProm = getUserInfo(searchConfig.userId);
            //     let usQProm = getUserQuotas(searchConfig.userId);
            //     let results = await Promise.all([usProm,usQProm]);
            //     setUserInfo(results[0]);
            //     setUserQuotas(results[1]);

            // }else{
                
            //     setUserInfo(appUserInfo);

            //     let usQtas = props.userInfo.extraData ? props.userInfo.extraData.quotas : null;

            //     setUserQuotas({
            //         ...usQtas
            //     });
            // }
           

            // if(!props.userInfo){

            //     let usProm = getUserInfo(searchConfig.userId);
            //     let usQProm = getUserQuotas(searchConfig.userId);
            //     //let catsProm = getCategories();
            //     //let itemsProm = getPublications(searchConfig.userId,searchConfig);

            //     let results = await Promise.all([usProm,usQProm]);

            //     setUserInfo(results[0]);
            //     setUserQuotas(results[1]);

            // }else{

               
            //     setUserInfo({
            //         uid: props.userInfo.uid,
            //         ...props.userInfo.basicData
            //     });

            //     let usQtas = props.userInfo.extraData ? props.userInfo.extraData.quotas : null;

            //     setUserQuotas({
            //         ...usQtas
            //     });

            // }
            
            
            //setIsNextPageLoading(true);
            let sts = getStates(pubStates);
            setFilterIndicator(sts);

            setNewFetch(true);

            
            //setCategories(results[2]);


            // for(let i=0; i< results[2].length; i++){
            //     uiStates[results[2][i].id] = {
            //         showRejectForm: false,
            //         rejectionMessage:results[2][i].state_reason || "",
            //         processing:false,
            //         errorMessage:null,
            //         success:false,
            //         dialog:false,
            //         dialogTitle:""
            //     }
            // }
            
            
            //setPublications(results[2]);

            //setFilteredPubs(results[3]);

            //let filtered = applyListFilter(results[2]);




           
            
            //await loadPubsImages(filtered);

            
            return true;
        }catch(err){
            setLoading(false);
            setErrorMessage(err.toString());
            console.error(err);
        }
    }

    const queryStringToJSON = queryString =>  {
        if(!queryString){
            return {};
        }

        if(queryString.indexOf('?') > -1){
          queryString = queryString.split('?')[1];
        }
        var pairs = queryString.split('&');
        var result = {};
        pairs.forEach(function(pair) {
          pair = pair.split('=');
          result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        return result;
    }

    useEffect(()=>{
        
        let obj = queryStringToJSON(window.location.search);
        setQueryObj(obj);

    },[window.location.search]);


    const chunk = (list, chunkSize) => {    
        if (!list.length) {
            return [];
          }
          if (typeof chunkSize === undefined) {
            chunkSize = 10;
          }
        
          var i, j, t, chunks = [];
          for (i = 0, j = list.length; i < j; i += chunkSize) {
            t = list.slice(i, i + chunkSize);
            chunks.push(t);
          }
        
          return chunks;
    }

    const loadPubsImages = async (pubs) => {
        try{

            let chunks = chunk(pubs,5);
           

            for(let i=0; i < chunks.length; i++){
                let proms = [];
                chunks[i].forEach(e => {
                    
                    let prom = fbStorage.child("pub_images/"+e.id+".jpg").getDownloadURL();
                    proms.push(prom);
                    if(!imageURLs[e.id]){
                        imageURLs[e.id] = {
                            status: "pending"
                        }
                        setImageURLs(imageURLs);
                    }
                    
                    
                    
                });
               
                let results = await Promise.allSettled(proms);
            
                chunks[i].forEach((e,ix) => {
                    imageURLs[e.id] = {
                        ...results[ix]
                    }   
                    
                });

                setImageURLs({
                    ...imageURLs
                });

            }

        }catch(err){
            throw err;
        }
    }


    const loadView = async queryObj => {

        try{

            setAppUserInfo({
                uid: props.userInfo.uid,
                ...props.userInfo.basicData,
                ...props.userInfo.extraData
            });

            if(props.userInfo.extraData.role === "admin"){
                setShopMode("shop");
            }else{
                setShopMode("usershop");
            }

            if(queryObj){

                if( queryObj.userId){
                   
                    await loadUserPublications(queryObj);

                    console.debug("Finished loading user publications",{userID:queryObj.userId});
                    
                }else if(props.userInfo){

                    if(props.userInfo.extraData.role === "admin"){
                        
                        await loadPublications();
                    }else{
                        
                        await loadUserPublications({
                            userId: props.userInfo.uid
                        });
                    }
    
                }else{
                   
                    let searchConfig = {
                        ...queryObj
                    };
    
                    if(!searchConfig.state){
                        searchConfig.state = "pending";
                    }
    
                    await loadPublications(searchConfig);
                }

            }

            return true;

        }catch(err){
            console.error("Error loading user publications",err);
            return false;
        }

    }


    useEffect(()=>{

        loadView(queryObj);
        

    },[queryObj]);

    if(loading){

        return (
            <div className={classes.loadingBox}>
                <CircularProgress className={classes.loadingSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.message}>Cargando publicaciones</p>
            </div>
        )
        
    }


    const reloadPublications = async () => {
        loadPublications();
    }

    if(errorMessage){
        return (
            <div className={classes.errorBox}>
                <ErrorMessageWidget 
                title="Ocurrió un error al consultar tus publicaciones"
                message={errorMessage}
                okBtnText="Reintentar"
                onOkBtnClicked={reloadPublications}
                />

            </div>
        )
    }

    const showReasonMessage = (pub,e) => {
        changePubUIState(pub,{dialogTitle: "Razón de rechazo",showRejectForm : true,rejectionMessage: pub.state_reason || ""});
        setSelectedItem(pub);
        setModalOpened(true);
        
    }


    const onRejectMessageChanged = (pub,e) => {
        changePubUIState(pub,{rejectionMessage:e.target.value});
    }

    const changePubUIState = (pub,obj) => {

        if(uiStates[pub.id]){

            let state = uiStates[pub.id];

            if(obj){
                Object.keys(obj).forEach(e => {
                
                    state[e] = obj[e];
                });
                
                uiStates[pub.id] = {
                    ...state
                };
                setUIStates(
                    {
                        ...uiStates
                    }
                );

                }

        }

        return uiStates;

    }

    const askConfirmation = async (pub,e) => {
         //approvePublication(selectedItem,e)
                            //cancelPublicationApproval(selectedItem,e)

        changePubUIState(pub,{dialogTitle:"Aprobar publicación",dialog:true,
        dialogMessage:"¿Aprobar publicación?",diagConfirmText:"Aprobar",
        onConfirmDialog: approvePublication,
        onCancelDialog: cancelPublicationApproval
        });

        setSelectedItem(pub);
        setModalOpened(true);
        return true;

    }

    const sendReactivationRequest = async pubId => {
        let response = await fetchPost(window.location.origin+"/api/"+shopMode+"/reactivatepublication",{
            pub_id:pubId
        });
       
        if(response.ok){
            let result = await response.text();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }

    const requestPubReactivation = async (pub,e) => {
        
        let pub2 = null;

        try{
          
          changePubUIState(pub,{processingMessage: "Guardando cambios...",processing:true,dialog:false});
            //pub2 = changePubUIState(pub,{processing:true,dialog:false});

            await sendReactivationRequest(pub.id);
            
            let pub2 = await updatePubList(pub);

            changePubUIState(pub2,{processing:false,errorMessage:""});

            setModalOpened(false);
            setSelectedItem(null);

            // if(filteredPubs.indexOf(pub3) !== -1){
            //     changePubUIState(pub3,{processing:false});
            // }

            return true;

        }catch(err){
            changePubUIState(pub,{processing:false,errorTitle:"Error",errorMessage:err.toString()});
        }

    }

    const askResetPubExpiration = async (pub,e) => {

        changePubUIState(pub,{dialogTitle:"Reactivar publicación",dialog:true,
        dialogMessage:"¿Reactivar esta publicación?",diagConfirmText:"Reactivar",
        onConfirmDialog: requestPubReactivation,
        onCancelDialog: cancelPublicationApproval
        });

        setSelectedItem(pub);
        setModalOpened(true);
        return true;

    }

    const updatePubList = async (pub) => {

        let searchConfig = {
            pub_id : pub.id,
            user_id: pub.userId
        };

        let ix = null;
        let index = filteredPubs.indexOf(pub);
       

        // for(let i=0; i < filteredPubs.length; i++){
        //     if(filteredPubs.id === pub.id){
        //         ix = i;
        //         break;
        //     }
        // }

        if(index >= 0){
            
                let pubData = await getPublicationData(searchConfig);
                let newPub = {
                    ...pubData
                }
        
                newPub.userInfo = pub.userInfo;
                
        
                filteredPubs.splice(index,1,newPub);
                setFilteredPubs([...filteredPubs]);
                return newPub;

            // if(queryObj && !queryObj.userId){

            //     filteredPubs.splice(index,1);
            //     if(filteredPubs.length > 0){
            //         setFilteredPubs([...filteredPubs]);
            //     }else{
            //         setPublications([]);
            //         setFilteredPubs([]);
            //     }
               
            //     return pub;
                
            // }else{
                

            //     let pubData = await getPublicationData(searchConfig);
            //     let newPub = {
            //         ...pubData
            //     }
        
            //     newPub.userInfo = pub.userInfo;
                
        
            //     filteredPubs.splice(index,1,newPub);
            //     setFilteredPubs([...filteredPubs]);
            //     return newPub;
            // }

            

        }else{

            return {};

        }

        
        

    }

    const approvePublication = async (pub,e) => {
        
        let pub2 = null;

        try{
          
          changePubUIState(pub,{processingMessage: "Marcando como aprobada...",processing:true,dialog:false});
            //pub2 = changePubUIState(pub,{processing:true,dialog:false});

            await sendChangeStateRequest(pub.userId,pub.id,"approved",null);
            
            let pub2 = await updatePubList(pub);

            changePubUIState(pub2,{processing:false,rejectionMessage:""});

            setModalOpened(false);
            setSelectedItem(null);

            // if(filteredPubs.indexOf(pub3) !== -1){
            //     changePubUIState(pub3,{processing:false});
            // }

            return true;

        }catch(err){
            changePubUIState(pub,{processing:false,errorTitle:"Error",errorMessage:err.toString()});
        }

    }

    const openRejectForm = (pub,e) => {

        changePubUIState(pub,{dialogTitle:"Rechazar publicación"});
        setSelectedItem(pub);
        setModalOpened(true);

        return true;
       
    }

    const closeRejectForm = (pub,e) => {

        setModalOpened(false);
        changePubUIState(pub,{dialog:false,showRejectForm:false,rejectionMessage:pub.state_reason || "", listItemSize: smallScreen ? 625 : 400 });

        return true;
    }

    const cancelPublicationApproval = (pub,e) => {

        setModalOpened(false);
        changePubUIState(pub,{showRejectForm:false,rejectionMessage:"",errorMessage:null,dialog:false});

        return true;
    }

    const rejectPublication = async (pub,e) => {
        let pub2 = null;

        try{

            changePubUIState(pub,{processingMessage: "Marcando como rechazada...",processing:true});

            await sendChangeStateRequest(pub.userId,pub.id,"rejected",uiStates[pub.id].rejectionMessage);
            
            pub2 = await updatePubList(pub);
            
            changePubUIState(pub,{processing:false,showRejectForm:false});

            setModalOpened(false);
            setSelectedItem(null);

            return true;

        }catch(err){
            changePubUIState(pub2 ? pub2 : pub,{processing:false,errorTitle:"Error",errorMessage:err.toString()});
        }
    }

    const closeItemErrorDialog = (pub,e) => {

        changePubUIState(pub,{errorMessage:""});

        return true;
    }

    const toggleFilterForm = e => {
        setFilterFormVisible(!filterFormVisible);
    }



    const statesReordered = states => {

        setPubStates(states);
        let sts = getStates(states);
        setFilterIndicator(sts);
       
    }

    const sendDeletionRequest = async (pubId,userId) => {
        let response = await fetchPost(window.location.origin+"/api/"+shopMode+"/deletepublication",{
            pub_id:pubId,
            user_id: userId
        });
       
        if(response.ok){
            let result = await response.text();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }

    const deletePublication = async pub => {
        console.debug("calling deletePublication ",pub);

        let ix = filteredPubs.indexOf(pub);

        await timeout(1000);
        await sendDeletionRequest(pub.id,pub.userId);
       
        filteredPubs.splice(ix,1);
        setFilteredPubs([...filteredPubs]);

        return true;

    }


    const goToEditPublication = item => {
        
        history.push("/app/new-publication?pub_id="+item.id+"&user_id="+item.userId);
        
    }

    const closeModal = e => {
        
        setSelectedItem(null);
        setModalOpened(false);
    }

    const createItemData = memoize((items,imageURLs,deletePublication,
        showReasonMessage,askResetPubExpiration,askConfirmation,openRejectForm,
        goToEditPublication,appUserInfo,userInfo,theme,uiStates,isItemLoaded,baseStoreURL) => ({
        items,
        imageURLs,
        deletePublication,showReasonMessage,askResetPubExpiration,askConfirmation,
        openRejectForm,goToEditPublication,appUserInfo,userInfo,theme,uiStates,isItemLoaded,baseStoreURL
      }));

      const isItemLoaded = index => {
        //console.log("isItemLoaded "+" "+!hasNextPage+" "+index+" "+filteredPubs.length+" "+(index < filteredPubs.length));
        return !hasNextPage || index < filteredPubs.length;
    }

    const itemData = createItemData(filteredPubs,imageURLs,deletePublication,
        showReasonMessage,askResetPubExpiration,askConfirmation,openRejectForm,
        goToEditPublication,appUserInfo,userInfo,theme,uiStates,isItemLoaded,baseStoreURL);



    const loadNextPage = async (...args) => {
        setIsNextPageLoading(true);
       
        setNewFetch(true);
        //await timeout(2000);

        return true;
    }

    const loadMoreItems = async (...args) => {
        console.debug("enters loadMoreItems: isNextPageLoading? "+isNextPageLoading,{...args});
        
        if(isNextPageLoading){
            return ;
        }else{
            return await loadNextPage(...args);
        }
       
    }

    const sendRevisionRequest = async pubId => {
        let response = await fetchPost(window.location.origin+"/api/usershop/requestrevision",{
            pub_id:pubId
        });
       
        if(response.ok){
            let result = await response.text();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }

    const sendPubToRevision = async (pub,e) => {
        // let newPub = {
        //     ...pub
        // };

        // let ix = filteredPubs.indexOf(pub);

        try{

            changePubUIState(pub,{processingMessage: "Enviando solicitud de revisión...",processing:true});

            // newPub.ui_extra.revisionSuccess = false;
            // newPub.ui_extra.sendingRevision = true;
            
            // filteredPubs.splice(ix,1,newPub);
            // setPublications([...publications]);

            await sendRevisionRequest(pub.id);
            // let newPub2 = {
            //     ...newPub
            // };

            // newPub2.ui_extra.sendingRevision = false;
            // newPub2.ui_extra.revisionSuccess = true;
            // newPub = newPub2;
            // newPub.state = "pending";
           
            // filteredPubs.splice(ix,1,newPub2);
            // setPublications([...publications]);

            // let newPub3 = {
            //     ...newPub2
            // };
            // newPub3.ui_extra.reasonMessageVisible = false;
            // newPub3.ui_extra.revisionSuccess = false;
            // newPub = newPub3;

            changePubUIState(pub,{processing:false});

            setModalOpened(false);
            setSelectedItem(null);
            
            // filteredPubs.splice(ix,1,newPub3);
            // setPublications([...publications]);

            return true;
        }catch(err){

            // let newPub2 = {
            //     ...newPub
            // };

            // newPub.ui_extra.sendingRevision = false;
            // newPub.ui_extra.revisionError = err.toString();
            // filteredPubs.splice(ix,1,newPub2);
            // setPublications([...publications]);

            changePubUIState(pub,{processing:false,errorTitle:"Error",errorMessage:err.toString()});

            return false;
        }

        
        
    }

    const hideReasonMessage = (pub,e) => {
        // let newPub = {
        //     ...pub
        // };
        
        // newPub.ui_extra.revisionError = null;
        // newPub.ui_extra.reasonMessageVisible = false;

        // filteredPubs.splice(filteredPubs.indexOf(pub),1,newPub);
        // setPublications([...publications]);
        setModalOpened(false);
        changePubUIState(pub,{revisionError:null,reasonMessageVisible : false});
    }

    return (
        <div className={classes.mainBox}>

            <div className={classes.titleBox}>
                <ListAltIcon className={classes.titleBoxIcon} />
                <p className={classes.title}>Administrar Publicaciones</p>
                <IconButton className={classes.filterBtn} onClick={toggleFilterForm}>
                    {
                        filterFormVisible ? 
                        (
                            <Cancel className={classes.filterIcon} />
                        )
                        :
                        (
                            <FilterListIcon className={classes.filterIcon} />
                        )
                    }
                </IconButton>
            </div>
            <Collapse in={filterFormVisible} style={{
                width:"100%"
            }}>
                <div  className={`${classes.filterBox} ${classes.lightGreyRounderBorder}`} style={{
                    flexDirection: smallScreen ? "column" : "row"
                }}>
                    <div className={classes.filterContentBox}>
                        <p style={{
                            color:"#607d8b",
                            margin:"0.5em"
                        }}>Ordenar y filtrar</p>
                        <SortableList  onListReordered={statesReordered} className={classes.lightGreyRounderBorder} items={pubStates} />
                    </div>
                    <div className={classes.filterActionBox}>
                        <Button size="small" variant="contained" 
                        onClick={e => applyListFilter(filteredPubs)}
                       
                        className={`${classes.filterActionBtn} ${classes.roundedBtn}`}>Aplicar</Button>
                    </div>
                </div>
            </Collapse>
            {
                filteredPubs && filteredPubs.length > 0 ?
                (
                    <p style={{
                        color:"#78909c",
                        fontSize:"0.9em",
                        width:"100%",
                        margin:"0.2em",
                        textAlign:"center"
                    }}>{"Filtrado y ordenado por: "+filterIndicator}</p>
                )
                :
                (
                    <div/>
                )
            }
                    
            {
                userInfo ?
                (
                    <div className={`${classes.flexColumn} ${classes.userMetaDataBox}`}>
                        <div className={`${classes.flexRow} ${classes.userMetaDataInnerBox}`}>
                            <p className={classes.userNameCounter}>{userInfo.displayName ? userInfo.displayName : userInfo.email}</p>
                            <div className={classes.postCounterBox}>
                            <p className={classes.postCounterText}>{filteredPubs ? filteredPubs.length : 0} / {userQuotas.max_pubs_allowed}</p>
                            
                            </div>
                        </div>
                        
                    </div>
                )
                :
                (
                    <div/>
                )
            }
            
            <div className={classes.contentBox} >
                {
                    filteredPubs && filteredPubs.length > 0 ?
                    (
                        <div style={{
                            width:"100%",
                            flex:"2"
                        }}>
                            <AutoSizer style={{
                                width:"100%",
                                height:"100%"
                            }}>
                                {
                                    ({height,width}) => (

                                        <InfiniteLoader
                                        threshold={1}
                                        isItemLoaded={isItemLoaded}
                                        itemCount={hasNextPage ? filteredPubs.length + 1 : filteredPubs.length}
                                        loadMoreItems={loadMoreItems}
                                        >
                                        {({ onItemsRendered, ref }) => (

                                            <List onItemsRendered={onItemsRendered} itemData={itemData} ref={ref}  height={height} width={width} itemSize={smallScreen ? 640 : 400} itemCount={hasNextPage ? filteredPubs.length + 1 : filteredPubs.length}>
                                                                                        
                                            {Row}

                                            </List>
                                        )}
                                        </InfiniteLoader>
                                        
                                    )
                                }
                            </AutoSizer>
                        </div>

                    )
                    :
                    (
                        <div className={classes.flexColumn} style={{
                            flex:"2",
                            justifyContent:"center"
                        }}>
                           
                           <p style={{
                                        color:"#9e9e9e",
                                        textAlign:"center"
                                    }}>{"Por el momento no quedan publicaciones."}</p>
                        </div>
                    )
                }
                
               
            </div>
            <Modal
                open={modalOpened}
                onClose={closeModal}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
                >
                <Fade in={modalOpened} >
                    <Card className={classes.flexColumn} style={{
                        width:smallScreen ? "80%" : "30em",
                        height:"20em",
                        padding:"1em",

                    }}>
                        
                        {
                           
                            selectedItem ? 
                            (
                                <AsyncBoxWidget 
                                processing={uiStates[selectedItem.id].processing}
                                success={uiStates[selectedItem.id].success}
                                errorTitle={uiStates[selectedItem.id].errorTitle}
                                dialog={uiStates[selectedItem.id].dialog}
                                dialogMessage={uiStates[selectedItem.id].dialogMessage}
                                diagConfirmText={uiStates[selectedItem.id].diagConfirmText}
                                diagCancelText="Cancelar"
                                onConfirmDialog={e => uiStates[selectedItem.id].onConfirmDialog(selectedItem,e)}
                                onCancelDialog={e => uiStates[selectedItem.id].onCancelDialog(selectedItem,e)}
                                successMessage="Listo!"
                                processingMessage={uiStates[selectedItem.id].processingMessage}
                                errorMessage={uiStates[selectedItem.id].errorMessage}
                                onDismissError={e => closeItemErrorDialog(selectedItem,e)}
                                className={classes.flexColumn} style={{
                                    fontFamily: "'Prompt', sans-serif",
                                    width:"100%",
                                    
                                }}>
                                    {
                                        appUserInfo.role === "admin" ?
                                        (
                                            <div className={classes.flexColumn}>
                                                <p className={classes.title}>{uiStates[selectedItem.id].dialogTitle}</p>
                                                <div className={classes.flexRow}>
                                                    <TextField label="Razón del rechazo"
                                                        multiline={true}
                                                        value={uiStates[selectedItem.id].rejectionMessage}
                                                        variant="outlined"
                                                        onChange={e => onRejectMessageChanged(selectedItem,e) }
                                                        placeholder="ej: El contenido no es apropiado para la plataforma"
                                                        rows={3}
                                                        style={{
                                                            width: smallScreen ? "100%" : "30em",
                                                            margin:"1.5em 0"
                                                        }}/>
                                                </div>
                                                <div className={classes.flexRow} style={{
                                                        justifyContent:"space-evenly",
                                                        margin:"1em 0"
                                                    }}>
        
                                                        <Button size="small" variant="contained" 
                                                        classes={{
                                                            root: `${classes.itemDangerBtn} ${classes.roundedBtn}`
                                                        }} 
                                                        onClick={e => rejectPublication(selectedItem,e)}>Guardar</Button>
        
                                                        <Button size="small" variant="contained" 
                                                        classes={{
                                                            root:`${classes.roundedBtn}`
                                                        }}
                                                        color="default"
                                                        onClick={e => closeRejectForm(selectedItem,e)}>Cancelar</Button>
        
                                                </div>
            
                                            </div>
                                        )
                                        :
                                        (
                                            <div className={classes.reasonInnerBox}>
                                                <p className={classes.reasonMessageText} style={{
                                                    maxWidth: smallScreen ? "95%" : "80%",
                                                    overflowY:"auto"
                                                }}>
                                                    {selectedItem.state_reason}
                                                </p>
                                                <div className={classes.reasonMessageActionsBox}>
                                                    <Button variant="contained" 
                                                    color="primary" 
                                                    className={classes.reasonMsgAcceptBtn} 
                                                    onClick={e => sendPubToRevision(selectedItem,e)}
                                                    >Ya hice los cambios, solicitar nueva revisión</Button>
                                                    <Button className={classes.reasonMsgCloseBtn} color="default" onClick={e => hideReasonMessage(selectedItem,e)}>Cerrar</Button>
                                                </div>
                                            </div>
                                        )
                                    }

    
                                </AsyncBoxWidget>
                            )
                            :
                            (
                                <div/>
                            )
                        }


                    </Card>
                </Fade>
            </Modal>
           
            
        </div>
        
      
      );

}

export default PublicationsManagerView;