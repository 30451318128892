import React from "react";
import Card from '@material-ui/core/Card';
import "./AccountPhoto.css";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const AccountPhoto = (props)=>{

   
    
    return (
      
      <Card className={"acc-photo-box "+props.className}>
      { props.photoURL ? <img src={props.photoURL} className="acc-img" ></img> : <AccountCircleIcon className="acc-icon" /> } 
      </Card>
    )


  }

  export default AccountPhoto;