import React,{useState,useEffect} from "react";
import "./ConfigView.css";
import {fetchPost,fetchGet} from "./AuthenticatedFetch";
import UserConfigPanel from "./UserConfigPanel";

    let timeout =  millis => {
        let prom = new Promise((resolve,reject)=>{
            setTimeout(()=>{
                resolve();
            },millis);
        });
        return prom;
    }

const  ConfigView = () => {
    const  config = {};
    return (
        <div >
            <h3 className="config-view-title">Configuración</h3>
            <div className="config-view-box">
                <UserConfigPanel config={config} />
            </div>
        </div>
        
    );
}

export default ConfigView;