import firebase from "./Firebase";

let config = {
    apiKey: "AIzaSyCY3CXH2zntFoUzhLtNWdnmJ_-g16kgkU0",
    authDomain: "tuestore-489f3.firebaseapp.com",
    databaseURL: "https://tuestore-489f3.firebaseio.com",
    projectId: "tuestore-489f3",
    storageBucket: "tuestore-489f3.appspot.com",
    messagingSenderId: "1046498170230",
    appId: "1:1046498170230:web:f6a5ae9583c1a35263d657"
  };

firebase.default.initializeApp(config);

const app = firebase;

app.auth().useDeviceLanguage();
export default app;