import React,{useEffect,useState} from "react";
import {Card,CircularProgress, TextField, InputLabel,
    FormControl,Select,FormHelperText,
     IconButton,MenuItem,ListItem,List,ListSubheader,ListItemIcon,ListItemText,Collapse} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import HomeIcon from '@material-ui/icons/Home';
import { useRouteMatch } from "react-router";
import {fetchGet,fetchPost} from "./AuthenticatedFetch";
import AddIcon from '@material-ui/icons/Add';
import {Check, ExpandLess,ExpandMore,Inbox as InboxIcon,StarBorder, TabRounded} from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';


const useStyles = makeStyles(theme => ({
    mainBox:{
        padding: "1em 1em 1em 1em",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
    },
    btn: {
        textTransform: "none",
        borderRadius:"2em"
    },
    loadingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      height:"100%"
    },
    loadingSpinner:{
      width:"2em!important",
      height:"2em!important"
    },
    dismissBtn:{
        textTransform:"none"
    },
    message:{
      margin:"0.6em 0.5em",
      fontSize:"1.2em"
    },
    errorBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      padding:"0.3em 0.5em",
      borderRadius:"0.3em",
      backgroundColor: red[50],
      color:red[900]
      },
      newPubBtn:{
        width:"20em",
        height:"5em",
        backgroundColor:"#4db6ac",
        color: "white",
        margin:"1em"
      },
      publicationsBtn:{
        width:"20em",
        height:"5em",
        backgroundColor:"#64b5f6",
        color: "white",
        margin:"1em"
      },
      titleBox:{
        padding: "1em",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
      },
      titleBoxIcon:{
          width:"5em",
          height:"5em",
          color:"#b0bec5",
          border:"1px solid #b0bec5",
          padding:"0.5em",
          borderRadius:"50%"
      },
      title: {
        fontFamily: "'Prompt', sans-serif",
        color:"#607d8b",
        fontSize:"1.5em",
        margin:"0"
      },
      contentBox:{
        padding: "1em",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
      },
      linkField: {
          width:"30em",
          maxWidth:"90%"
      },
      loadingCatBox:{
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
        justifyContent:"center",
        width:"30em",
        margin:"4em 0 1em 0",
            maxWidth:"90%",
            height:"20em",
      },
      loadingCatSpinner:{
        width:"2em!important",
        height:"2em!important"
      },
      dismissBtn:{
          textTransform:"none"
      },
      loadingCatMessage:{
        margin:"0.6em 0.5em",
        fontSize:"1em"
      },
      errorCatBox:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        width:"30em",
        height:"20em",
        margin:"4em 0 1em 0",
            maxWidth:"90%",
        padding:"0.3em 0.5em",
        borderRadius:"0.3em",
        backgroundColor: red[50],
        color:red[900]
        },
        errorCatMessage:{
            margin:"0.6em 0.5em",
            fontSize:"1em",
            textAlign:"center"
        },
        itemsListBox: {
            margin:"3em 0 3em 0",
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            justifyContent:"flex-start",
            width:"30em",
            maxWidth:"90%",
            height:"20em"
        },
        formControl:{
            width:"100%"
        },
        itemListInnerBox: {
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            justifyContent:"center",
            width:"100%"
        },
        newItemBox: {
            marginTop:"2em",
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            justifyContent:"center",
            width:"100%"
        },
        newItemInnerBox: {
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            justifyContent:"center",
            width:"100%",
            transition:"opacity 0.2s linear"
        },
        newItemInput:{
            width:"100%"
        },
        newItemBtnBox: {
            marginTop:"1em",
            display:"flex",
            alignItems:"center",
            justifyContent:"space-evenly",
            width:"100%"
           
        },
        listItemBox :{
            padding:"0.2em",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            width:"100%"
        },
        subMenuBox :{
            padding:"0.2em",
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            justifyContent:"center",
            width:"100%"
        },
        itemList:{
            height:"15em",
            maxHeight:"15em",
            overflowY:"auto",
            width:"25em",
            maxWidth:"90%",
            padding:"0.2em",
            border: "1px solid #e0e0e0",
            borderRadius:"0.2em"
        },
        selectedItemField:{
            width:"25em",
            maxWidth:"90%",
            marginBottom:"0.5em"
        }
  }));

let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}

const useListStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested0: {
        paddingLeft: theme.spacing(2),
        fontWeight:"bold"
    },
    nested1: {
        paddingLeft: theme.spacing(4),
    },
    nested2: {
      paddingLeft: theme.spacing(6),
    },
    nested3: {
        paddingLeft: theme.spacing(8),
    },
    nested4: {
        paddingLeft: theme.spacing(10),
    },
    txt_lvl_0: {
        fontWeight:"bold"
    },
    txt_lvl_1: {
       
    },
    txt_lvl_2: {
      
    },
    txt_lvl_3: {
        
    },
    txt_lvl_4: {
        
    },
    selected:{
        backgroundColor: "#03a9f4",
        color:  "white",
        "&:hover":{
            backgroundColor: "#0288d1",
        }
    },
    normal:{
        backgroundColor: "transparent",
        color:  "#455a64",
    }
  }));





const MenuItemNode = props => {

    const listClasses = useListStyles();
    const [selected,setSelected] = useState(false);
    // const itemClicked = node => {
        
    //     setSelected(!selected);
    //     props.itemClicked(node);
    // }
    
    useEffect(() => {
        if(props.selectedItems){

            let ix = props.selectedItems.indexOf(props.node);
            console.log("item found? "+ix);

            if(ix >= 0){
                setSelected(true);
            }else{
                setSelected(false);
            }

        }
    },[props.selectedItems]);

    if (!props.node.children) return (
        <ListItem 
        
        onClick={e => props.itemClicked(props.node)} value={props.node.id} button className={`${listClasses["nested"+props.depth]} ${ selected ? listClasses.selected : listClasses.normal }  `}> 
            {/* {
                props.node.selected ?
                (
                    <Check />
                )
                :
                (<div/>)
            } */}
            <ListItemText classes={{
                primary:listClasses["txt_lvl_"+props.depth]
            }} primary={props.node.label} /> 
        </ListItem>
    );

    return (
        <div>
             <ListItem disabled={props.depth === 0} onClick={e => props.itemClicked(props.node)} value={props.node.id} className={`${listClasses["nested"+props.depth]} ${ selected ? listClasses.selected : listClasses.normal }  `} button >
                {/* {
                    props.node.selected ?
                    (
                        <Check />
                    )
                    :
                    (<div/>)
                } */}

                <ListItemText classes={{
                    primary:listClasses["txt_lvl_"+props.depth]
                }} primary={props.node.label} />
            
            </ListItem>
            <List component="div" disablePadding>

                {props.node.children.map(c => ( <div  key={c.id}><MenuItemNode selectedItems={props.selectedItems} itemClicked={props.itemClicked} depth={props.depth + 1}  node={c} /></div>))}
                
            </List>
            
        </div>
    );

}

const ItemChooser = (props)=> {
    const classes = useStyles();
   

    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);
    const [itemsDisplay,setItemsDisplay] = useState("");
    const [items,setItems] = useState([]);
    // const [newItemVisible,setNewItemVisible] = useState(false);
    // const [listEnabled,setListEnabled] = useState(true);
    // const [newItemName,setNewItemName] = useState("");
    

    const displaySelectedItems = () => {
        let text = [];
        selectedItems.forEach(e => {
            text.push(e.label);
        });
        setItemsDisplay( text.join(", "));
        return text.join(", ");
    }

    const loadItems = async () => {

        try{

            setLoading(true);
            setErrorMessage(null);
            let items = await props.loadItems();
            setItems(items);
            setLoading(false);
           

            return true;

        }catch(err){
            setLoading(false);
            setErrorMessage(err);
            console.error(err);
        }

    }

    const findNode = (currentNode,id) => {
        let i,
            currentChild,
            result;
    
        if (id == currentNode.id) {
            return currentNode;
        } else {
    
            // Use a for loop instead of forEach to avoid nested functions
            // Otherwise "return" will not work properly
            if(currentNode.children){
                for (i = 0; i < currentNode.children.length; i += 1) {
                    currentChild = currentNode.children[i];
        
                    // Search in the current child
                    result = findNode(currentChild,id );
        
                    // Return the result if the node has been found
                    if (result !== false) {
                        return result;
                    }
                }

                
            }

            return false;
            
    
            // The node has not been found and we have no more options
          
        }
    }

    const lookItemRec = id => {
        let it= null;
        for(let i=0; i< items.length; i++){
           
            it = findNode(items[i],id);
            if(it){
                break;
            }
        }
        return it;
    }

    useEffect(()=>{
        loadItems();
    },[]);

    useEffect(()=> {

        if(items.length > 0){
            if(!props.selectedItemsId){
                //setSelectedItems([items[0]]);
                //props.onItemChanged(items[0]);
                
            }else{
                console.log(props.selectedItemsId);
                props.selectedItemsId.forEach(e => {
                    let it = lookItemRec(e);
                    console.log(it);
                    if(it){
                        selectedItems.push(it);
                        
                        setSelectedItems([...selectedItems]);
                        //props.onItemChanged(it);
                        
                    }
                });

                displaySelectedItems();
               
            }

            
            props.onItemsChanged(selectedItems);
            
            
        }

    },[items])

    const [selectedItems,setSelectedItems] = useState([]);

    if(loading){

        return (
            <div className={classes.loadingCatBox}>
                <CircularProgress className={classes.loadingCatSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.loadingCatMessage}>{props.loadingMsg}</p>
            </div>
        )
        
    }

    const dismissErrorMsg = () => {
        setErrorMessage(null);
    }

    if(errorMessage){
        return (
            <div className={classes.errorCatBox}>
                <p className={classes.errorCatMessage}>{errorMessage}</p>
                <div>
                    <Button size="small" className={classes.dismissBtn} onClick={dismissErrorMsg} >OK</Button>
                </div>
            </div>
        )
    }

    // const getItemById = id => {
        
    //     let item = null;
    //     for(let i=0; i< items.length; i++){
    //         if(items[i].id === id){
    //             item = items[i];
    //             break;
    //         }
    //     }
    //     return item;
    // }

    // const itemChanged = (e,v,r) => {
    //     console.log(v);
       
    //     // let selItem = getItemById(e.target.value);
    //     // console.log(e.target);
    //     // // ghAction.action_data = {};
    //     // setSelectedItem({...selItem});
    //     // console.log("cambio item");
    //     // console.log(selectedItem);
    //     // props.onItemChanged(selectedItem);

    // }

    // const newItemNameChanged = e => {
    //     setNewItemName(e.target.value);
    // }

    // const showNewItemForm = evt => {
    //     console.log("add new item!");
    //     setListEnabled(false);
    //     setNewItemVisible(true);
    // }

    // const resetNewItemForm = () => {

    // }

    // const cancelNewItemForm = evt => {
    //     console.log("add new item!");
    //     setNewItemVisible(false);
    //     setListEnabled(true);
    //     resetNewItemForm();
    // }

    // const saveNewItem = async () => {
    //     try{
    //         setLoading(true);
    //         setErrorMessage(null);
    //         let returnedItem = await props.onSaveItem({
    //             name: newItemName
    //         });
            
    //         setNewItemVisible(false);
    //         setListEnabled(true);
    //         resetNewItemForm();
    //         await loadItems();
    //         setSelectedItem(returnedItem);
    //         setLoading(false);

    //         return true;
    //     }catch(err){
    //         setLoading(false);
    //         setErrorMessage(err);
    //         console.error(err);
    //     }
       
    // }




    const nodeClicked = node => {
               
        // ghAction.action_data = {};
        let ix = selectedItems.indexOf(node);
        if(ix !== -1){
            selectedItems.splice(ix,1);
        }else{
            selectedItems.push(node);
        }
       
        setSelectedItems([
            ...selectedItems
        ]);

        displaySelectedItems();

        props.onItemsChanged(selectedItems);
    }

    return (
            <div className={classes.itemsListBox}>
                <div className={classes.itemListInnerBox}>
                
                <TextField multiline rowsMax={4} className={classes.selectedItemField} variant="outlined" value={itemsDisplay} label="Elegí una o más categorías" />
                <List component="nav" className={classes.itemList}>
                        {
                                items.map((it,ix) => (
                                    <MenuItemNode selectedItems={selectedItems} itemClicked={nodeClicked} 
                                    
                                    depth={0} value={it.id} key={it.id} node={it} />
                                ))
                        }
                </List>
                
                    

               
                    {/* <IconButton variant="contained" color="primary" onClick={showNewItemForm}>
                        <AddIcon />
                    </IconButton>  */}
                </div>
                {/* <div className={classes.newItemBox}>
                    <div className={classes.newItemInnerBox} style={{
                        opacity:newItemVisible ? 1: 0
                    }}>
                        <TextField label={props.newItemInputLabel} 
                        className={classes.newItemInput} 
                        value={newItemName}
                        onChange={newItemNameChanged}
                        > </TextField>
                        <div className={classes.newItemBtnBox}>
                            <Button onClick={saveNewItem} className={classes.btn} color="primary" variant="contained">Guardar</Button>
                            <Button onClick={cancelNewItemForm} color="default" className={classes.btn} variant="contained">Cancelar</Button>
                        </div>
                    </div>
                    
                </div> */}
                
                
            </div>
        
      
      );

}

export default ItemChooser;