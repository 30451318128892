import React, { useContext } from "react";
import {Route, Redirect} from "react-router-dom";
import {AuthContext} from "./Auth"; 

const PrivateRoute = ({ component: RouteComponent,...rest }) => {
    
    const {loginState} = useContext(AuthContext);
   
    return (
        <Route
            {...rest}
            render={ routeProps => 
                loginState.currentUser ? (
                    <RouteComponent {...routeProps}></RouteComponent>
                ) : (
                    
                    <Redirect to={{
                        pathname: '/signin',
                        state: { from: routeProps.location }
                      }}></Redirect>
                )
            }
        >

        </Route>
    )

};

export default PrivateRoute