import React,{useState,useEffect} from "react";
import "./UserConfigPanel.css";
import {fetchPost,fetchGet} from "./AuthenticatedFetch";
import {Card,CircularProgress,Typography,Button,createMuiTheme} from '@material-ui/core';
import EditableInput from "./EditableInput";
//import ProfitMarginsTable from "./ProfitMarginsTable";
import RangeValueMap from "./RangeValueMap";
import { red,pink } from '@material-ui/core/colors';




// Ellos en su cuenta asignaria 4 cosas, 
// 1) el coste por libra, 
// 2) el porcentaje de ganancia, 
// 3) el arrancel que es un porcentaje tambien  y 
// 4) el stock

    let timeout =  millis => {
        let prom = new Promise((resolve,reject)=>{
            setTimeout(()=>{
                resolve();
            },millis);
        });
        return prom;
    }


    const errorTheme = createMuiTheme({ palette: { primary: red },textBox: {
        backgroundColor:red[50],
        padding: "1em",
        borderRadius:"0.3em",
        color: pink[900],
        margin:"0.3em",
        textAlign:"center"
    },icon: {
        margin:"0.3em",
        color: red[300]
    },primaryBtn:{
        backgroundColor:red[300],
        color:"white"
    }
     })

const  UserConfigPanel = (props) => {
    

    // {profitPercentage:30,initialValue:1000,finalValue:10000},
    //     {profitPercentage:25,initialValue:10001,finalValue:20000},
    //     {profitPercentage:20,initialValue:20001,finalValue:30000},
    //     {profitPercentage:18,initialValue:30001}

    const [config,setConfig] = useState(null);
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);

    const getUserConfig = async ()=> {

        try{

            setLoading(true);
            setErrorMessage(null);
            let response = await fetchGet(window.location.origin+"/api/users/config");
            let result = await response.json();
            console.log(result);
            setConfig(result);
            setLoading(false);
            return true;

        }catch(err){

            setLoading(false);
            setErrorMessage(err.toString());

        }

    }

    const saveUserConfig = async (userConfig)=> {

        try{

            //setLoading(true);
            await fetchPost(window.location.origin+"/api/users/saveconfig",userConfig);
            //setLoading(false);
            return true;

        }catch(err){

            //setLoading(false);
            console.error(err);

        }

    }

    const [profitMargins,setProfitMargins] = useState([]);

    const costPerPoundEditConfig = {
       
        label:"Costo por libra",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        type:"number",
        loadingText:"Guardando",
        successText: "Guardado",
        dismissErrorText: "Ok",
        helperText:"Coste por cada libra de peso del producto",
        value:(config&&config.costPerPound || "0")
    }

    const [costPerPoundConfig,setCostPerPoundConfig] = useState(costPerPoundEditConfig);

    const onConfirmCostPerPoundValue = async (newValue)=>{
        try {
            console.log(newValue);
            let userConfig = config;
            userConfig.costPerPound = parseFloat(newValue);
            console.log(userConfig);
            await saveUserConfig(userConfig);
            setCostPerPoundConfig(costPerPoundEditConfig);
            return true;
        }catch(err){
            throw err;
        }
    }

    const tariffEditConfig = {
        label:"Arancel (%)",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        loadingText:"Guardando",
        type:"number",
        successText: "Guardado",
        dismissErrorText: "Ok",
        helperText:"Impuesto por importación",
        value:(config&&config.tariff || 0)
    }

    const [tariffConfig,setTariffConfig] = useState(tariffEditConfig);

    const onConfirmTariffValue = async (newValue)=>{
        
        try{
            let userConfig = config;
            userConfig.tariff = parseFloat(newValue);
            console.log(userConfig);
            await saveUserConfig(userConfig);
            setTariffConfig(tariffEditConfig);
            return true;
        }catch(err){
            throw err;
        }

    }


    const stockThresholdEditConfig = {
        label:"Stock",
        type:"number",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        loadingText:"Guardando",
        successText: "Guardado",
        dismissErrorText: "Ok",
        helperText:'Stock mínimo en tus productos para que sean considerados "disponibles"',
        value:(config&&config.stockThreshold || 0)
    }

    const [stockThresholdConfig,setStockThresholdConfig] = useState(stockThresholdEditConfig);

    const onConfirmStockThresholdValue = async (newValue)=>{
        
        let userConfig = config;
        userConfig.stockThreshold = parseInt(newValue);
        await saveUserConfig(userConfig);
        setStockThresholdConfig(stockThresholdEditConfig);
        return true;
    }

   const sortRanges = (profMarginsArray) => {
       profMarginsArray.sort((a, b) => {
           
        return a.priceFrom < b.priceFrom ? -1 : 1;
       });
       
   }

    const onRowAdded = async (newData) => {

        let userConfig = JSON.parse(JSON.stringify(config));
        let arr = config.profitMargins&&config.profitMargins.slice() || [];
        arr.push(newData);
        sortRanges(arr);
        userConfig.profitMargins = arr;
        await saveUserConfig(userConfig);
        setConfig(userConfig);
        return true;

    }

    const onRowUpdated = async (newData,oldData) => {
        
        let userConfig = JSON.parse(JSON.stringify(config));;
        let arr = config.profitMargins&&config.profitMargins.slice() || [];
        let ix = arr.indexOf(oldData);
        arr[ix] = newData;
        sortRanges(arr);
        userConfig.profitMargins = arr;
        await saveUserConfig(userConfig);
        setConfig(userConfig);
        return true;

    }

    const onRowDeleted = async (oldData) => {

        let userConfig = JSON.parse(JSON.stringify(config));;
        let arr = config.profitMargins&&config.profitMargins.slice() || [];
        let ix = arr.indexOf(oldData);
        arr.splice(ix,1);
        sortRanges(arr);
        userConfig.profitMargins = arr;
        await saveUserConfig(userConfig);
        setConfig(userConfig);
        return true;

    }

    useEffect(()=>{
        getUserConfig();
    },[]);

    if(loading){
        return (
            <div className="user-config-panel">
                <div className="main-box">
                    <CircularProgress variant="indeterminate"></CircularProgress>
                    <p>Cargando configuración...</p>
                </div>
            </div>
        )
    }

    if(errorMessage){
        return (
            <div className="user-config-panel">
                <div className="error-box">
                    <div style={errorTheme.textBox}>
                        <p>Hubo un error al cargar la configuración: </p>
                        <Typography style={errorTheme.textBox} variant="body2" component="p">
                            
                            {errorMessage}
                        </Typography>
                    </div>
                    
                        
                    <Button style={errorTheme.primaryBtn} variant="contained" className="action-btn" onClick={getUserConfig}>Reintentar</Button>
                    
                </div>
            </div>
        )
    }

    return (
        <div className="user-config-panel">
            
        </div>
        
    );
}

export default UserConfigPanel;