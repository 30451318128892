import React from 'react';
import './App.css';
import {BrowserRouter as Router,Route,Redirect, Switch} from "react-router-dom";


// import firebase from "./Firebase";
import SignIn from "./SignIn";
import SignUp from  "./SignUp";
import {AuthProvider} from "./Auth";
import PrivateRoute from "./PrivateRoute";
import ResponsiveDrawer from "./ResponsiveDrawer";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBox: {
 
   height:"100%",
   width:"100%",
   display:"flex"
  }
}));

function App() {

  let classes = useStyles();

  return (
    <AuthProvider>
      
        <Router>
          <div className={classes.appBox}>
            <Switch>
              <PrivateRoute path="/app" component={ResponsiveDrawer}></PrivateRoute>
              <Route exact path="/signin" component={SignIn}></Route>
              <Route exact path="/signup" component={SignUp}></Route>
              <Route exact path="/">
                <Redirect to="/app"></Redirect>
              </Route>
            </Switch>
          </div>
        </Router>
      
    </AuthProvider>
  );
}

export default App;
