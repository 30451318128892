import React,{useContext,useState,useEffect} from 'react';
import {AppBar,useMediaQuery} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import SettingsIcon from '@material-ui/icons/Settings';

import Badge from '@material-ui/core/Badge';
import HomeIcon from '@material-ui/icons/Home';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ConfigView from "./ConfigView";
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';


import {  Switch,Route,useRouteMatch,Link,Redirect,useHistory} from "react-router-dom";


import AccountWidget from "./AccountWidget";

import { AuthContext } from "./Auth.js";
import AccountView from './AccountView';
import { grey } from '@material-ui/core/colors';
import {fetchGet, fetchPost} from "./AuthenticatedFetch";

import HomeView from './HomeView';
import AdminHomeView from './AdminHomeView';
import NewPublicationsView from './NewPublicationsView';
//import PublicationsView from './PublicationsView';

import PublicationsManagerView from './PublicationsManagerView';
import UsersView from './UsersView';
import AdminConfigView from './AdminConfigView';

import firebase from "./Firebase";
import RealTimeNotificationWidget from './RealTimeNotificationWidget';
import FSRealTimeManager from './FSRealTimeManager';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex:"2"
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor:"#263238"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    display:"flex",
    flexDirection:"column",
    maxWidth:"100%",
    padding:"0.5em",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color:"#616161"
  },
  subIconClasses:{
    "color":grey[500]
  },
  appTitle: {
    fontFamily: "'Prompt', sans-serif",
  }
}));


function ResponsiveDrawer(props) {


  let { path } = useRouteMatch();



  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [showPubBadge,setShowPubBadge] = useState(false);

  const history = useHistory();
  
  const goToRejectedPublications = evt => {
    notifWidgetClosed();
    history.push("/app/publications-manager?state=rejected");
  }

  const [notifWidgetConfig, setNotifWidgetConfig] = useState({
    show:false,
    actionMessage:"",
    type:"warning",
    message:"",
    functionToCall: () => {},
    altActionMessage:"",
    altActionToCall: () => {}
  });

  const { loginState } = useContext(AuthContext);
  const displayName = loginState.currentUser.providerData[0].displayName || loginState.currentUser.providerData[0].email.split("@")[0];
  const photoURL = loginState.currentUser.providerData[0].photoURL;

  const userFullInfo = {
    uid: loginState.currentUser.uid,
    basicData: loginState.currentUser.providerData[0],
    extraData: loginState.userInfo
  }

  const [fsNotifManager, setFSNotifManager] = useState();

  useEffect(() => {

    let fsRealtime = new FSRealTimeManager(fetchPost,firebase.firestore(),"notifications");

    fsRealtime.onData( data => {

      console.log("data que viene ");
      console.log(data);

      if(data.length > 0){
        let obj = {
          show:true,
          actionMessage:"Ver Publicaciones",
          type:"danger",
          message:"Tienes publicaciones rechazadas",
          altActionMessage:"Descartar",
          functionToCall: async () => {
  
            goToRejectedPublications();
            await fsRealtime.resetNotifications();
          },
          altActionToCall: async () => {
            notifWidgetClosed();
            await fsRealtime.resetNotifications();
           
            console.log("notifications reset successfully");
        
            return true;
          }
        }

        setNotifWidgetConfig(obj);
        setShowPubBadge(true);
  
       
      }else{
        setShowPubBadge(false);
      }
      

    });

    fsRealtime.subscribeToNotifications(loginState.currentUser.uid,"publications_rejected");

    setFSNotifManager(fsRealtime);

    return () => {
        console.log("unsubscribing from notifications...");
        fsRealtime.unsubscribeFromNotifications();
    }

  },[]);

  const handleDrawerToggle = (e) => {
    
    setMobileOpen(!mobileOpen);
  };

  const hideDrawer = (e) => {
    
    setMobileOpen(false);
  };

  const notifWidgetClosed = () => {
    let obj = {
      ...notifWidgetConfig
    }

    obj.show = false;

    setNotifWidgetConfig(obj);
  }

  const clearPubNotifications = async () => {
      await fsNotifManager.resetNotifications();
      return true;
  }

  const drawer = (
    <div  >
      
      <AccountWidget displayName={displayName} photoURL={photoURL}></AccountWidget>
      <Divider />

      {
        loginState.userInfo.role === "admin" ?
        (
          <List component="nav">

                <ListItem button key="home" component={Link} to={`${path}/admin-home`}>
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem button key="users" component={Link} to={`${path}/users`}>
                    <ListItemIcon><PersonIcon/></ListItemIcon>
                    <ListItemText primary="Usuarios" />
                </ListItem>
                <ListItem button key="publications-manager" component={Link} to={`${path}/publications-manager`}>
                    <ListItemIcon><PlaylistAddCheckIcon/></ListItemIcon>
                    <ListItemText primary="Revisiones" />
                </ListItem>
                <ListItem button key="admin-config" component={Link} to={`${path}/admin-config`}>
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText primary="Configuración" />
                </ListItem>
                
          </List>
        )
        :
        (
          <List component="nav">

                <ListItem button key="home" component={Link} to={`${path}/home`}>
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem button key="account" component={Link} to={`${path}/account`}>
                    <ListItemIcon><AccountBoxIcon/></ListItemIcon>
                    <ListItemText primary="Mi Cuenta" />
                </ListItem>
                <ListItem button key="publications" component={Link} to={`${path}/publications-manager`}>
                    <ListItemIcon><ListAltIcon/></ListItemIcon>
                    <Badge invisible={!showPubBadge} color="secondary" variant="dot">
                      <ListItemText primary="Mis Publicaciones" />
                    </Badge>
                </ListItem>
                <ListItem button key="new-publication" component={Link} to={`${path}/new-publication`}>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary="Publicar producto" />
                </ListItem>

                {/* <ListItem button key="config" component={Link} to={`${path}/config`}>
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText primary="Configuración" />
                </ListItem> */}
                
          </List>
        )
      }


    </div>
  );

  

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {
            loginState.userInfo.role === "admin" ?
            (
              <Typography variant="h6" noWrap className={classes.appTitle}>
                TodoTiendas - Admin
              </Typography>
            )
            :
            (
              <Typography variant="h6" noWrap className={classes.appTitle}>
                TodoTiendas
              </Typography>
            )
          }
          

        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onClick={hideDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
            
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
            
        {
        loginState.userInfo.role === "admin" ?
          (
            <Switch>
                <Route path={`${path}/admin-home`}>
                    <AdminHomeView />
                </Route>
                <Route path={`${path}/users`}>
                    <UsersView />
                </Route>
                <Route path={`${path}/new-publication`}>
                    <NewPublicationsView userInfo={userFullInfo} />
                </Route>
                <Route path={`${path}/publications-manager`}>
                    <PublicationsManagerView userInfo={userFullInfo} />
                </Route>
                
                <Route path={`${path}/admin-config`}>
                    <AdminConfigView />
                </Route>
               
                <Route path="**">
                  <Redirect to={`${path}/admin-home`}></Redirect>
                </Route>
            </Switch>
          )
          :
          (
            <Switch>
                <Route path={`${path}/home`}>
                    <HomeView userInfo={userFullInfo} />
                </Route>
                <Route path={`${path}/account`}>
                    <AccountView />
                </Route>
                <Route path={`${path}/new-publication`}>
                    <NewPublicationsView userInfo={userFullInfo} />
                </Route>
                <Route path={`${path}/publications-manager`}>
                    <PublicationsManagerView pendingNotifications={showPubBadge}
                    clearPubNotifications={clearPubNotifications}
                    userInfo={userFullInfo} />
                </Route>
                {/* <Route path={`${path}/publications`}>
                    <PublicationsView />
                </Route> */}
                <Route path={`${path}/config`}>
                    <ConfigView />
                </Route>
               
                <Route path="**">
                  <Redirect to={`${path}/home`}></Redirect>
                </Route>
            </Switch>
          )
        }
            
      </main>
      <RealTimeNotificationWidget show={notifWidgetConfig.show} position={
        smallScreen ? 
        {
          vertical:"top",
          horizontal:"center"
        }
        :
        {
          vertical:"bottom",
          horizontal:"right"
        }
      } 
        onClose={notifWidgetClosed}
        type={notifWidgetConfig.type}
        message={notifWidgetConfig.message}
        functionToCall={notifWidgetConfig.functionToCall}
        actionMessage={notifWidgetConfig.actionMessage}
        altActionMessage={notifWidgetConfig.altActionMessage}
        altActionToCall={notifWidgetConfig.altActionToCall}

      />
    </div>
  );
}

export default ResponsiveDrawer;
