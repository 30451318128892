import app from "./base";

export const fetchPost = async (url,body)=>{
    let idToken = await app.auth().currentUser.getIdToken(true);
    let response = await fetch(url,{
        method:"POST",
        headers: new Headers({
            'Authorization': 'Bearer '+idToken, 
            'Content-Type': 'application/json'
        }),
        body:JSON.stringify(body)
    });

    return response;
}

export const fetchGet = async (url,params)=>{

    let idToken = await app.auth().currentUser.getIdToken(true);
    let urlWithParams = new URL(url);
    if(params){
        Object.keys(params).forEach(key => urlWithParams.searchParams.append(key, params[key]))
    }
    
    let response = await fetch(urlWithParams,{
        method:"get",
        headers: new Headers({
            'Authorization': 'Bearer '+idToken, 
            'Content-Type': 'application/json'
        })
    });

    return response;
}