import React,{useEffect,useState} from "react";
import {Card,CircularProgress, TextField,InputLabel,FormControl,Select,FormHelperText} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import HomeIcon from '@material-ui/icons/Home';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import { useHistory, useRouteMatch } from "react-router";
import {fetchGet,fetchPost} from "./AuthenticatedFetch";
import ItemChooser from "./ItemChooser";
import MessageWidget from "./MessageWidget";

const useStyles = makeStyles(theme => ({
    mainBox:{
        padding: "1em 1em 1em 1em",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
    },
    btn: {
        textTransform: "none"
    },
    loadingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      height:"100%"
    },
    loadingSpinner:{
      width:"2em!important",
      height:"2em!important"
    },
    dismissBtn:{
        textTransform:"none",
        margin:"0.5em 0.5em"
    },
    message:{
      margin:"0.6em 0.5em",
      fontSize:"1.2em"
    },
    errorBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"column",
      width:"100%",
      padding:"0.3em 0.5em",
      borderRadius:"0.3em",
      backgroundColor: red[50],
      color:red[900]
      },
      errorTitle: {
           
        color:"#616161",
        fontSize:"1.5em",
        
    },
      newPubBtn:{
        width:"20em",
        height:"5em",
        backgroundColor:"#4db6ac",
        color: "white",
        margin:"1em"
      },
      publicationsBtn:{
        width:"20em",
        height:"5em",
        backgroundColor:"#64b5f6",
        color: "white",
        margin:"1em"
      },
      titleBox:{
        padding: "1em",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
      },
      titleBoxIcon:{
          width:"5em",
          height:"5em",
          color:"#b0bec5",
          border:"1px solid #b0bec5",
          padding:"0.5em",
          borderRadius:"50%"
      },
      title: {
        fontFamily: "'Prompt', sans-serif",
        color:"#607d8b",
        fontSize:"1.5em",
        margin:"0"
      },
      contentBox:{
        padding: "1em",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
      },
      linkField: {
          width:"30em",
          maxWidth:"90%"
      },
      nameField: {
        width:"30em",
        maxWidth:"90%",
        margin:"2em 0"
      },
      descField: {
        width:"30em",
        maxWidth:"90%",
        margin:"2em 0 0 0"
        },
        priceField: {
            width:"25em",
            maxWidth:"90%"
        },
        btnBox:{
            marginTop:"2em",
            padding: "0.5em",
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        },
        okBtn:{
            textTransform:"none",
            width:"10em",
            margin:"0 0.5em"
        },
        cancelBtn:{
            textTransform:"none",
            width:"10em",
            margin:"0 0.5em"
        },
        savedBox:{
            marginTop:"2em",
            padding: "0.5em",
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            justifyContent:"center"
        },
        savedTitle: {
           
            color:"#388e3c",
            fontSize:"1.5em",
            
        },
        savedIcon:{
            width:"3em",
            height:"3em",
            color:"#4caf50",
        },
        errorIcon:{
            width:"2em",
            height:"2em",
            color:"#e57373",
        },
        errorBtn:{
            backgroundColor:"#e57373",
            color:"white",
            textTransform:"none",
            margin:"0.5em 0.5em"
        }
        
  }));






  const useInfoStyles = makeStyles(theme => ({
        infoBox:{
            margin:"2em",
            padding: "1em",
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            justifyContent:"center",
            borderRadius:"0.3em",
            backgroundColor:"#e1f5fe",
            maxWidth:"90%",
            width:"30em"
        },
        infoText: {
            color:"#455a64",
            fontSize:"1.2em",
            textAlign:"justify"
        },
        infoIcon:{
            width:"1.5em",
            height:"1.5em",
            color:"#039be5",
            margin:"0.5em 0",
        }
        
        
  }));









let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}


const NewPublicationView = (props)=> {
    const classes = useStyles();

    const infoClasses = useInfoStyles();

    const [title,setTitle] = useState("Nueva Publicación");

    const [loading,setLoading] = useState(false);
    const [saving,setSaving] = useState(false);

    const [saved,setSaved] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);

    const [selectedCategories,setSelectedCategories] = useState(null);

    const [instagramLink,setInstagramLink] = useState("");

    const [name,setName] = useState("");

    const [description,setDescription] = useState("");

    const history = useHistory();

    const [price,setPrice] = useState(0);

    const match = useRouteMatch();

    const [queryObj,setQueryObj] = useState(null);

    const [maxPubsReachedResult,setMaxPubsReachedResult] = useState(null);

    const [checkingMaxPubsReached,setCheckingMaxPubsReached] = useState(false);

    const [publicationToEdit,setPublicationToEdit] = useState(null);

    const queryStringToJSON = queryString =>  {
        if(!queryString){
            return {};
        }

        if(queryString.indexOf('?') > -1){
          queryString = queryString.split('?')[1];
        }
        var pairs = queryString.split('&');
        var result = {};
        pairs.forEach(function(pair) {
          pair = pair.split('=');
          result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        return result;
    }

    useEffect(()=>{

        console.log(window.location.search);

        let obj = queryStringToJSON(window.location.search);
        if(!obj || (obj && !obj.pub_id)){
            console.log("va a querer agregar uno nuevo");
            if(!checkingMaxPubsReached){
                checkPubsLimit();
            }
        }

        setQueryObj(obj);
       



    },[window.location.search]);


    const getPublicationData = async (pubId,userId) => {

        let response = null;
        if(userId  && props.userInfo.extraData.role === "admin"){
            response = await fetchGet(window.location.origin+"/api/shop/publication?pub_id="+pubId+"&user_id="+userId);
        }else{
            response = await fetchGet(window.location.origin+"/api/usershop/publication?pub_id="+pubId);
        }
        
        if(response.ok){
            
            let result = await response.json();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }
    }

    const checkIfMaxReached = async () => {
        let response = await fetchGet(window.location.origin+"/api/usershop/maxpubsreached");
        
        if(response.ok){
            
            let result = await response.json();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }
    }

    const loadItemEdition = async (pubId,userId) => {
        try{

            setLoading(true);
            setErrorMessage(null);
            setTitle("Editar Publicación");

            let pub = await getPublicationData(pubId,userId);
            setPublicationToEdit(pub);
            setName(pub.name);
            setDescription(pub.description);
            setPrice(pub.price);
            setInstagramLink(pub.instagram_link);

            setLoading(false);
            
            return true;

        }catch(err){
            setLoading(false);
            setErrorMessage(err.toString());
            console.error(err);
        }
    }

    const checkPubsLimit = async () => {
        try{
            setCheckingMaxPubsReached(true);
            setLoading(true);
            setErrorMessage(null);

            let res = await checkIfMaxReached();
            setMaxPubsReachedResult(res);
            setLoading(false);
            
            return true;

        }catch(err){
            setLoading(false);
            setErrorMessage(err.toString());
            console.error(err);
        }
    }

    useEffect(()=>{

        if(queryObj && queryObj.pub_id){
            console.log("check if there is a pub_id in query object");
            console.log(queryObj);
            loadItemEdition(queryObj.pub_id,queryObj.user_id);
        }

    },[queryObj]);

    const resetView = () => {
        setPrice(0);
        setInstagramLink("");
        setDescription("");
        setName("");
        setSaved(false);
        setErrorMessage(null);
        setPublicationToEdit(null);
    }

    const cancelPublication = evt => {
       
        resetView();
        if(queryObj && queryObj.user_id){
            history.push("/app/publications-manager?userId="+queryObj.user_id);
        }else{
            history.push("/app/publications");
        }
        
    }


    if(maxPubsReachedResult && maxPubsReachedResult.reached){
       
        return (
            <div className={classes.mainBox}>
                <MessageWidget 
                type="info"
                title={"Llegaste al límite de publicaciones: "+maxPubsReachedResult.pubsCount+" / "+maxPubsReachedResult.maxPubsAllowed}
                message="Ponte en contacto con nosotros si necesitas aumentarlo o consideras que existe un error."
                okBtnText="Ir a Mis Publicaciones"
                onOkBtnClicked={cancelPublication}
                />

            </div>
        )
        
        
    }


    const getAvailableCategories = async ()=> {
        let response = await fetchGet(window.location.origin+"/api/prestashop/categories/tree");
        
        if(response.ok){
            
            let result = await response.json();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }
    }

    const requestItemPublication = async pub => {
        let response = await fetchPost(window.location.origin+"/api/usershop/requestpublication",pub);
        //console.log(response);
        
        if(response.ok){
            let result = await response.json();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }
    }

    const saveItem = async pub => {
        let response;

        if(queryObj && queryObj.user_id && props.userInfo.extraData.role === "admin"){
            response = await fetchPost(window.location.origin+"/api/shop/savepublication",{
                user_id: queryObj.user_id,
                item:pub
            });
        }else{
            response = await fetchPost(window.location.origin+"/api/usershop/savepublication",pub);
        }
        
        //console.log(response);
        
        if(response.ok){
            let result = await response.text();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }
    }

    const getCategoriesIds = cats => {
        let ids = [];
        cats.forEach(e => {
            ids.push(e.id);
        })
        return ids;
    }

    const savePublication = async e => {
        try{
            setSaving(true);
            setErrorMessage(null);

            if(!name){
                throw "Es necesario especificar un nombre para el producto"
            }

            if(!selectedCategories || selectedCategories.length === 0){
                throw "Es necesario que elijas al menos una categoría para tu publicación"
            }

            let newPub = {
                name: name,
                instagram_link : instagramLink,
                categories: getCategoriesIds(selectedCategories),
                description: description,
                price: price
            }

            if(publicationToEdit){
                newPub.id = publicationToEdit.id;
                console.log(publicationToEdit);
               
                let result = await saveItem(newPub);
            }else{
                let result = await requestItemPublication(newPub);
            }

           
            
            setSaving(false);
            setSaved(true);

            return true;
        }catch(err){
            setSaving(false);
            setErrorMessage(err.toString());
            console.error(err);
        }
    }





    // const saveCategory = async (category)=> {
    //     let response = await fetchPost(window.location.origin+"/api/usershopcategories/save",category);
    //     //console.log(response);
        
    //     if(response.ok){
    //         let result = await response.json();


    //         return {
    //             id: result.id,
    //             label: result.name
    //         };
    //     }else{
    //         let error = await response.text();
    //         throw error;
    //     }
    // }

    if(loading){

        return (
            <div className={classes.loadingBox}>
                <CircularProgress className={classes.loadingSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.message}>Cargando</p>
            </div>
        )
        
    }

    if(saving){

        return (
            <div className={classes.loadingBox}>
                <CircularProgress className={classes.loadingSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.message}>{publicationToEdit ? "Guardando...": "Publicando..."}</p>
            </div>
        )
        
    }

    if(saved){

        return (
            <div className={classes.savedBox}>
                <CheckIcon className={classes.savedIcon}  />
                <p className={classes.savedTitle}>Listo! Tu publicación fue {publicationToEdit ? "guardada": "creada"} con éxito</p>
                
                {
                    !publicationToEdit ?
                    (
                        <div  className={infoClasses.infoBox}>
                            <InfoIcon className={infoClasses.infoIcon}  />

                                <p className={infoClasses.infoText}>
                            
                                    Haremos una breve revisión del contenido. 
                                    Si todo está en orden, en breve verás tu publicación en la tienda.
                                    Podrás consultar el estado de tus publicaciones en <strong>Mis Publicaciones</strong>
                                </p>
                            
                        </div>
                    )
                    :
                    (
                        <div/>
                    )
                }

                {
                    !publicationToEdit ?
                    (
                        <Button size="small" color="primary" variant="contained" className={classes.dismissBtn} onClick={resetView}>Hacer otra publicación</Button>
                    )
                    :
                    (
                        <div/>
                    )
                }
                
                <Button size="small" color="default" variant="contained" className={classes.dismissBtn} onClick={cancelPublication}>Ir a Mis Publicaciones</Button>
            </div>
        )
        
    }

    const closeErrorMessage = () => {
        setErrorMessage(null);
    }

    if(errorMessage){
        return (
            <div className={classes.errorBox}>
                <ErrorIcon className={classes.errorIcon}  />
                <p className={classes.errorTitle}>Ocurrió un error</p>
                <p className={classes.message}>{errorMessage}</p>
                <Button size="small" variant="contained" className={classes.errorBtn} onClick={closeErrorMessage}>Reintentar</Button>
            </div>
        )
    }

    const setNewCategories = newCats => {
        
        setSelectedCategories(newCats);
    }

    const priceChanged = e => {
       
        try{
            if(!e.target.value){
                setPrice(0);
            }else{
               
                let p = parseInt(e.target.value);
                setPrice(p);
                
            }
            
        }catch(err){
            
        }
    }

    const nameChanged = e => {
        setName(e.target.value);
    }

    const instaLinkChanged = e => {
        setInstagramLink(e.target.value);
    }

    const descChanged = e => {
        setDescription(e.target.value);
       
    }

    const loadAvailableCategories = async () => {
        let cats = await getAvailableCategories();
        // cats.forEach(c => {
          
        //     delete c["name"];
        // });
        return cats;
    }
    
    return (
        <div className={classes.mainBox}>
            <div className={classes.titleBox}>
                <p className={classes.title}>{title}</p>
            </div>
            <div className={classes.contentBox}>

                <TextField label="Nombre del producto"
                onChange={nameChanged}
                value={name}
                placeholder="ej: Remera Negra"
                variant="outlined"
                className={classes.nameField}/>

                <TextField label="Link de Instagram"
                multiline={true}
                variant="outlined"
                onChange={instaLinkChanged}
                value={instagramLink}
                placeholder="ej: https://www.instagram.com/p/CHqpsxQDI1E"
                rows={3}
                 className={classes.linkField}/>

                <TextField label="Descripción"
                multiline={true}
                variant="outlined"
                onChange={descChanged}
                value={description}
                placeholder="ej: Remera negra estampada - verano"
                rows={3}
                 className={classes.descField}/>

                <ItemChooser className={classes.itemChooser} 
                inputLabel="Categoría del producto" 
                loadingMsg="Cargando categorías" 
                newItemInputLabel="Nombre para la nueva categoría"
                loadItems={loadAvailableCategories}
                selectedItemsId={publicationToEdit ? ( 
                    publicationToEdit.category_id ? [publicationToEdit.category_id]:  publicationToEdit.categories  )  : null }
                // onSaveItem={saveCategory}
                onItemsChanged={setNewCategories}
                />

                <TextField label="Precio"
                  type="number"
                  onChange={priceChanged}
                  value={price}
                  placeholder="ej: 100"
                 className={classes.priceField}/>

                 <div className={classes.btnBox}>
                    <Button size="medium" color="primary" variant="contained" className={classes.okBtn}
                    onClick={savePublication}
                    >{publicationToEdit ? "Guardar" : "Publicar"}</Button>
                    <Button size="medium" color="default" variant="contained" 
                    className={classes.cancelBtn} 
                    onClick={cancelPublication}
                    >Cancelar</Button>
                 </div>

            </div>
            
        </div>
        
      
      );

}

export default NewPublicationView;