
import React from "react";
import "./AccountWidget.css";
import AccountPhoto from "./AccountPhoto";
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import app from "./base";
import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    displayName: {
      textAlign: "center"
    },
    toolbarBox:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-evenly"
    }
}));

const AccountWidget = (props)=> {

    let classes = useStyles();

    let logout = (e)=>{
        app.auth().signOut();
    }

    return (
        <Toolbar >
            <div className={classes.toolbarBox}>
            <AccountPhoto className="acc-widget-photo" photoURL={props.photoURL}></AccountPhoto>
            <div className="data-action-box">
                <p className={classes.displayName}>{props.displayName}</p>

                <Button className="btn" onClick={logout}>Cerrar sesión</Button>
            </div>
            </div>
        
        </Toolbar>
      
      );

}

export default AccountWidget;









