import React, {  useState } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Checkbox } from "@material-ui/core";

const SortableList = (props) => {
  const [items, setItems] = useState(props.items);

  const onDrop = ({ removedIndex, addedIndex }) => {
    let newItems = arrayMove(items, removedIndex, addedIndex);
    setItems(newItems);
    props.onListReordered(newItems);
  };

  const checkChanged = (item,e) => {
    let newItems = [
        ...items
    ]

    let it = {
        ...item
    }
    it.enabled = e.target.checked;
    let i = newItems.indexOf(item);
    newItems.splice(i,1,{
        ...it
    });
   
    setItems(newItems);
    props.onListReordered(newItems);

  }

  return (
    <List>
      <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
        {items.map(item => (
          <Draggable key={item.id}>
              <div style={{
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"flex-start"
              }}>
                <Checkbox checked={item.enabled} onChange={e => checkChanged(item,e)}  />
                  <p style={{
                      flex:"2",
                      margin:"0 0.5em 0 0"
              }} >{item.text}</p>
                <DragHandleIcon className="drag-handle" />
              </div>

          </Draggable>
        ))}
      </Container>
    </List>
  );

};

export default SortableList