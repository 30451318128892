import React, { useCallback, useContext,useState } from "react";
import { withRouter, Redirect } from "react-router";
import app from "./base.js";
import { AuthContext } from "./Auth.js";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress,TextField,Icon } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
//import {AccountCircle,VpnKey }from '@material-ui/icons';
import "./SignIn.css";

const useStyles = makeStyles(theme => ({
    margin: {
      margin: theme.spacing(2),
    },
    socialLoginBtn: {
      width: "14em",
      margin:"0.5em"
    },
    instaLoginBtn: {
      width: "14em",
      height:"4em",
      margin:"0.5em"
    },
    socialLoginBox:{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center"
    },
    largeIcon: {
      fontSize:"2.1em",
      marginLeft: "0.5em"
    },
    signInForm:{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding:"1em"
    },
    brandTitle: {
      fontFamily: "'Prompt', sans-serif",
      fontSize:"3em"
    },
    loginMsg:{
      marginBottom:"0"
    },
    submitBtn:{
      margin: "2em 0 0 0",
      textTransform: "none",
      borderRadius: "4em",
      background: "linear-gradient(45deg, rgba(2,119,189,1) 0%, rgba(3,155,229,1) 74%, rgba(79,195,247,1) 100%)", 
      color: "white"
    }
  }));

  const useSocialLoginBtnStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#FFFFFF",
      justifyContent:"space-around"
    },
    label:{

    }
  }));

const SignIn = (props) => {

    const { from } = props.location.state || { from: { pathname: '/' } };

const classes = useStyles();

const socialLoginBtnClasses = useSocialLoginBtnStyles();

  const { loginState } = useContext(AuthContext);

  const [loading,setLoading] = useState(true);
  const [errorMessage,setErrorMessage] = useState(null);

  const login = async (event)=>{
    event.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    const { email, password } = event.target.elements;
    try {
      
      await app
        .auth()
        .signInWithEmailAndPassword(email.value, password.value);
        
      //history.push("/home");
      
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.toString());
      
    }
  };


  const loginWithGoogle = async ev => {

    ev.preventDefault();
    setLoading(true);
    setErrorMessage(null);

    try{
      
      let provider = new app.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      let result = await app.auth().signInWithPopup(provider);

      return true;

    }catch(err){
      setLoading(false);
      setErrorMessage(err.toString());
    }

  }


  const loginWithInstagram = async ev => {

    ev.preventDefault();
    setLoading(true);
    setErrorMessage(null);

    try{
      
      window.location.href = "/api/instagram/redirect";

      return true;

    }catch(err){
      setLoading(false);
      setErrorMessage(err.toString());
    }

  }

    if(loginState.loading){
        return (
            <div className="main-box">
                <CircularProgress variant="indeterminate"></CircularProgress>
                <p>Iniciando sesión...</p>
            </div>
          )
    }else{
        if(loginState.currentUser){
            return <Redirect to={from} />;
        }
    }
   
    // if (loading) {
    //     return (
    //         <div className="main-box">
    //             <CircularProgress variant="indeterminate"></CircularProgress>
    //             <p>Iniciando sesión...</p>
    //         </div>

    //     )
        
    // }

    
    
    const GoogleIcon = () => {
      
      return (
        <Icon style={{
          width:"auto",
          height:"100%"
        }}>
          <img width="auto" height="100%" alt="google" src="img/btn_google_light_normal_ios.svg" />
        </Icon>
      );
    }

    return (
      <div className="main-box">
        <h1 className={classes.brandTitle}>TodoTiendas</h1>
        {
          errorMessage ?
          (
            <div className="error-text-box">
                <p className="error-text justified">{errorMessage}</p>
            </div>
          )
          :
          (
            <div></div>
          )

        }
        <form onSubmit={login} className={classes.signInForm}>
            
            <TextField className={classes.margin} label="Email" name="email" type="email" 
            placeholder="usuario@gmail.com" autoComplete="username" 
            
            />

            <TextField className={classes.margin} label="Contraseña" name="password" type="password" 
            autoComplete="current-password" 
            
            />
            
            <Button variant="contained" className={classes.submitBtn} type="submit">Iniciar sesión</Button>
        </form>

        <p>¿No tienes una cuenta? <a href="/signup">crear una</a></p>

        <div className={classes.socialLoginBox}>
          <Button
            variant="contained"
            size="large"
            className={classes.socialLoginBtn}
            classes={{
              root: socialLoginBtnClasses.root, // class name, e.g. `classes-nesting-root-x`
             
            }}
            startIcon={<GoogleIcon />}
            onClick={loginWithGoogle}
          >
            
            Google
          </Button>

          {/* <Button
            variant="contained"
            size="large"
            className={classes.instaLoginBtn}
            classes={{
              root: socialLoginBtnClasses.root, // class name, e.g. `classes-nesting-root-x`
              iconSizeLarge: classes.largeIcon
            }}
            startIcon={<InstagramIcon />}
            onClick={loginWithInstagram}
          >
            
            Instagram
          </Button> */}
          
        </div>
      </div>
        

    );

  
};

export default withRouter(SignIn);