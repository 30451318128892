import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton,Paper,TextField,CircularProgress,createMuiTheme,Button} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { red,pink,blue } from '@material-ui/core/colors';   
import "./RangeValueMap.css"; 
import WarningIcon from '@material-ui/icons/Warning';
import { cleanup } from '@testing-library/react';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}

const mainStyle = {
    root: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start",
        width:"100%",
        minHeight:"28em",
        padding:"1em"
    },
    header: {
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        width:"100%",
        padding:"0.5em"
    },
    rangeFormBox: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        width:"100%",
        padding:"0.5em",
        flex:"2"
    },
    rangFormTextField: {
        margin: "1em 0"
    },
    rangeFormActionsBox:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width:"100%",
        padding:"0.5em"
    },
    loadingBox: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        width:"100%",
        flex:"2",
        padding:"0.5em"
    },
    title: {
        margin:"0.3em",
        fontSize:"1.1em",
        fontWeight:"bold",
        flex:"2"
    },
    subtitle: {
        margin:"0.3em",
        fontSize:"1em",
        color:"#616161",
        padding:"0.5em",
        textAlign:"center"
    },
    mainBox: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        width:"100%",
        flex:"2",
        padding:"0.5em"
    },
    listBox: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start",
        width:"100%",
        flex:"2"
    },
    listItemActionsBox: {
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        width:"100%",
        padding:"0.5em"
    },
    listItemBody: {
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        width:"100%"
    },
    editItemButton:{
        color:blue[500],
        height:"1em",
        width:"1em",
        padding:"0"
    },
    deleteItemButton:{
        color:red[500],
        height:"1em",
        width:"1em",
        padding:"0"
    },
    editItemIcon:{
        fontSize:"0.8em"
    },
    deleteItemIcon:{
        fontSize:"0.8em"
    },
    itemGroup: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        padding:"0.5em"
    },
    itemLabel: {
        fontWeight:"bold",
        margin:"0.5em 0"
    },
    itemValue:{
        color: "#616161",
        margin:"0.5em 0"
    },
    deleteItemBox: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        padding:"0.5em"
    },
    deleteItemActionsBox: {
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
        width:"100%",
        padding:"0.5em"
    },
    itemLoadingBox: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        width:"100%",
        padding:"0.5em"
    },
    warningInconsistencyBox: {
        padding: "1em",
        borderRadius:"0.5em",
        margin:"1em 0",
        backgroundColor:"#fff9c4",
        color:"#795548",
        textAlign:"center"
    }
};



const errorTheme = createMuiTheme({ palette: { primary: red },textBox: {
    backgroundColor:red[50],
    padding: "1em",
    borderRadius:"0.3em",
    color: pink[900],
    margin:"0.3em",
    textAlign:"center"
},icon: {
    margin:"0.3em",
    color: red[300]
},primaryBtn:{
    backgroundColor:red[300],
    color:"white",
    margin:"1em 0"
}
 })


function RangeListItem (props) {

    const [deleteDialogVisible,setDeleteDialogVisible] = useState(false);
    const [loading,setLoading] = useState(false);
    const [loadingMessage,setLoadingMessage] = useState(null);
    const [errorMessage,setErrorMessage] = useState(null);

    const onDeleteButtonTap = ()=>{
        console.log(props.item);
        setDeleteDialogVisible(true);
    }


    const useStyles = makeStyles(mainStyle);

    const classes = useStyles();

    const onDeleteCheckButtonTap = async ()=>{
        try{
            setLoadingMessage(props.deletingMessage);
            setLoading(true);

            await props.deleteItemCallback(props.item);
            setLoading(false);
            setDeleteDialogVisible(false);
            return true;

        }catch(err){
            setErrorMessage(err.toString());
            setLoading(false);

            throw err;
        }
    }

    // useEffect(()=>{
    //     return function cleanup() {
    //         setLoading(false);
    //         setDeleteDialogVisible(false);
    //         setErrorMessage(null);
    //     }
    // });

    const onDeleteCloseButtonTap = ()=>{
        setDeleteDialogVisible(false);
    }

    const resetError = ()=>{
        setErrorMessage(null);
        setLoading(false);
        setDeleteDialogVisible(false);
    }

    if(loading){
        return (
            <div className={`range-list-item-box ${props.className}`}>
                <div className={classes.itemLoadingBox}>
                    <CircularProgress variant="indeterminate"></CircularProgress>
                    <p>{loadingMessage}</p>
                </div>
            </div>
        )
    }

    if(errorMessage){
        return (
            <div className="error-box">
                <div style={errorTheme.textBox}>
                    <p>{props.error.title}</p>
                    <p style={errorTheme.textBox} >
                        
                        {errorMessage}
                    </p>
                </div>
                
                <Button style={errorTheme.primaryBtn} size="small" variant="contained" className="action-btn" onClick={resetError}>{props.error.closeBtnText}</Button>
                
            </div>
        )
    }

    if(deleteDialogVisible){
        return (
            <div className={`range-list-item-box ${props.className}`}>
                <div className={classes.deleteItemBox}>
                    <p>{props.deleteDialogMessage}</p>
                    <div className={classes.deleteItemActionsBox}>
                        <IconButton onClick={onDeleteCheckButtonTap}>
                            <CheckIcon  />
                        </IconButton>

                        <IconButton onClick={onDeleteCloseButtonTap}>
                            <ClearIcon  />
                        </IconButton>
                    </div>
                </div>
            </div>
            
        );
    }


    let itemGroups = [];
    
    itemGroups = props.fields.map((fieldItem) => {
        let itemKeys = Object.keys(props.item);

        let label = "";
        let value = "";
        for(let i=0;i<itemKeys.length;i++){
            if(itemKeys[i] === fieldItem.field){
                label =fieldItem.label;
                value = props.item[itemKeys[i]];
                break;
            }
        }
       
        return <div className="range-item-group" key={props.fields.indexOf(fieldItem)} >
                <p className={classes.itemLabel}>{label}</p>
                <p className={classes.itemValue}>{value}</p>
               
            </div>

    });
    

    return (
        <div className={`range-list-item-box ${props.className}`}>
            <div className={classes.listItemActionsBox}>
                <IconButton className={classes.editItemButton}  onClick={props.onEditButtonTap} >
                    <EditIcon className={classes.editItemIcon} />
                </IconButton>
                <span style={{flex:"2"}}></span>
                <IconButton className={classes.deleteItemButton} onClick={onDeleteButtonTap} >
                    <DeleteIcon className={classes.deleteItemIcon} />
                </IconButton>
            </div>
            <div className="range-list-item-body">
                {itemGroups}
            </div>
        </div>
    )
}

export default function RangeValueMap(props) {
    const useStyles = makeStyles(mainStyle);
    const classes = useStyles();
    const [loading,setLoading] = useState(false);
    const [showRangeForm,setShowRangeForm] = useState(false);
    const [loadingMessage,setLoadingMessage] = useState("Cargando...");
    const [errorMessage,setErrorMessage] = useState(null);
    let baseRange = {};
    baseRange[props.fields[0].field] = 0;
    baseRange[props.fields[1].field] = 0;
    baseRange[props.fields[2].field] = 0;

    const [currentRange,setCurrentRange] = useState(JSON.parse(JSON.stringify(baseRange)));
    const [originalRange,setOriginalRange] = useState(null);

//    const [valueLimits,setValueLimits] = useState({});

    const [fieldErrors,setFieldErrors] = useState({});

    const [rangesInconsistent,setRangesInconsistent] = useState(false);

    const onAddRangeButtonTapped = async ()=>{

        baseRange[props.fields[0].field] = 0;
        baseRange[props.fields[1].field] = 1;

        if(props.data&&props.data.length === 0){

            // baseRange[props.fields[1].field] = 0;
            // baseRange[props.fields[2].field] = 1;

            // valueLimits[props.fields[1].field] = {
            //     minValue: 0
            // }

            // valueLimits[props.fields[2].field] = {
            //     minValue: 1
            // }

            //setValueLimits(JSON.parse(JSON.stringify(valueLimits)));
            //setCurrentRange(JSON.parse(JSON.stringify(baseRange)));
        }else{

            // if there is already a range in data array
            //then take the superior value of that range as the minimum value
            //for this new range

            // let lastRange = props.data[props.data.length-1];
            // baseRange[props.fields[1].field] = lastRange[props.fields[2].field] + 1;
            // baseRange[props.fields[2].field] = lastRange[props.fields[2].field] + 2;


            // valueLimits[props.fields[1].field] = {
            //     minValue: lastRange[props.fields[2].field] + 1
            // }

            // valueLimits[props.fields[2].field] = {
            //     minValue: lastRange[props.fields[2].field] + 2
            // }

            //setValueLimits(JSON.parse(JSON.stringify(valueLimits)));

            
        }
        
        setCurrentRange(JSON.parse(JSON.stringify(baseRange)));

        setShowRangeForm(true);
    }

    const onCloseRangeButtonTapped = ()=>{
        setShowRangeForm(false);
        setFieldErrors({});
    }

    const resetError = ()=>{
        setErrorMessage(null);
    }

    const onFieldChanged = (e) => {

        let field = e.target.getAttribute("name");
        let type = e.target.getAttribute("type");

        if(type === "number"){
            currentRange[field] = parseFloat(e.target.value) || 0;
        }else{
            currentRange[field] = e.target.value || "";
        }

        // if(field === props.fields[2].field){

        //     valueLimits[props.fields[1].field].maxValue = parseFloat(e.target.value) -1;

        //     setValueLimits(JSON.parse(JSON.stringify(valueLimits)));
        // }

        // if(field === props.fields[1].field){

        //     valueLimits[props.fields[2].field].minValue = parseFloat(e.target.value) + 1;

        //     setValueLimits(JSON.parse(JSON.stringify(valueLimits)));
        // }


        
        setCurrentRange(JSON.parse(JSON.stringify(currentRange)));

    }

    // const validateForm = () => {    



    //     let allCorrect = true;
    //     props.fields.forEach( e => {
    //         console.log(typeof e.validator);
    //         if(typeof e.validator === "function"){
    //             console.log("entrando a validator de "+e.field);
    //             try{

    //                 e.validator(currentRange[e.field],valueLimits[e.field]&&valueLimits[e.field].minValue,valueLimits[e.field]&&valueLimits[e.field].maxValue)
    //             }catch(err){
    //                 allCorrect = false;
    //                 fieldErrors[e.field] = err.toString();
    //                 console.log(err);
    //                 setFieldErrors(JSON.parse(JSON.stringify(fieldErrors)));
    //             }
                
    //         }
           
    //     });

    //     return allCorrect;
        
    // }

    const detectRangeGapsAndOverlaps = () => {
        
        if(props.data&&props.data.length > 1){
            let inconsistencyDetected = false;
            let r0LastValue = props.data[0][props.fields[1].field];
            let r1FirstValue = props.data[1][props.fields[0].field];
            let diff = r1FirstValue - r0LastValue;
            if(diff > 1){
                //gap detected
                inconsistencyDetected = true;
                console.log("detectado gap entre 0 y 1");

            }

            if(diff <= 0){
                //overlap detected
                //setRangesInconsistent(true);
                inconsistencyDetected = true;
                console.log("detectado solapamiento entre 0 y 1");
            }

            for(let i=2;i<props.data.length;i++){

                let prevItemLastValue = props.data[i-1][props.fields[1].field];
                let currItemFirstValue = props.data[i][props.fields[0].field];
                let diff = currItemFirstValue - prevItemLastValue;
                console.log(currItemFirstValue+" "+prevItemLastValue+" "+diff);
                if(diff > 1){
                    //gap detected
                    
                        //setRangesInconsistent(true);

                        inconsistencyDetected = true;
                    
                    
                    console.log("detectado gap entre "+(i-1)+" y "+i);
                    break;

                }

                if(diff <= 0){
                    //overlap detected
                    console.log("detectado solapamiento entre "+(i-1)+" y "+i);
                    
                        //setRangesInconsistent(true);
                        inconsistencyDetected = true;
                    
                    break;
                }

               

            }

            setRangesInconsistent(inconsistencyDetected) ;
        }

        

        return rangesInconsistent;
        
    }

    useEffect(()=>{
        console.log("el data que tiene");
        console.log(props.data);
        detectRangeGapsAndOverlaps();
    },[props.data]);

    const onCheckButtonTapped = async () => {

        try{
            setFieldErrors({});
            setLoadingMessage("Guardando...");
            setLoading(true);

            if(originalRange){
                await props.onRangeUpdate(JSON.parse(JSON.stringify(currentRange)),originalRange);
            }else{
                await props.onRangeAdd(JSON.parse(JSON.stringify(currentRange)));
            }
           
            //detectRangeGapsAndOverlaps();
            setOriginalRange(null);
            setLoading(false);
            setShowRangeForm(false);
            
            
        }catch(err){
            setLoading(false);
            setShowRangeForm(false);
            setErrorMessage(err.toString());
            
            throw err;
        }
        
    }
    
    const onEditButtonTap = (rangeItem) => {
       
        setOriginalRange(rangeItem);
        setCurrentRange(JSON.parse(JSON.stringify(rangeItem)));
        setShowRangeForm(true);
        
    }

    const onDeleteButtonTap = async () => {

        console.log("mostrar un dialogo de confirmacion para borrar el item");
        
    }

    const onDeleteRange = async (item) => {
        try{
            await props.onRangeDelete(item);
            //await detectRangeGapsAndOverlaps();
        }catch(err){
            throw err;
        }
        
    }

    if(loading){
        return (
            <Paper className={classes.root}>
                <div className={classes.loadingBox}>
                    <CircularProgress variant="indeterminate"></CircularProgress>
                    <p>{loadingMessage}</p>
                </div>
            </Paper>
        )
    }
    
    if(errorMessage){
        return (
            <Paper className={classes.root}>
                <div className="error-box">
                    <div style={errorTheme.textBox}>
                        <p>{props.error.title}</p>
                        <p style={errorTheme.textBox} >
                            
                            {errorMessage}
                        </p>
                    </div>
                    
                        
                    <Button style={errorTheme.primaryBtn} variant="contained" className="action-btn" onClick={resetError}>{props.error.closeBtnText}</Button>
                    
                </div>
            </Paper>
        )
    }

    if(showRangeForm){
        return (
            <Paper className={classes.root}>
                <div className={classes.rangeFormBox}>
                        
                        <TextField className={classes.rangeFormTextField} label={props.fields[0].label} 
                        type={props.fields[0].type}
                        name={props.fields[0].field}
                        //error={fieldErrors[props.fields[0].field]&&fieldErrors[props.fields[0].field].length > 0}
                        //helperText={fieldErrors[props.fields[0].field]}
                        placeholder={props.rangeForm.valuePlaceholder}
                        value={currentRange[props.fields[0].field]}
                        onChange={onFieldChanged}
                        />

                        <TextField className={classes.rangeFormTextField} label={props.fields[1].label} 
                        type={props.fields[1].type} 
                        name={props.fields[1].field}
                        
                        // inputProps={{ min: valueLimits[props.fields[1].field].minValue,
                        //     min: valueLimits[props.fields[1].field].maxValue  , step: "1" }}
                        
                        //error={fieldErrors[props.fields[1].field]&&fieldErrors[props.fields[1].field].length > 0}
                        //helperText={fieldErrors[props.fields[1].field]}

                        value={currentRange[props.fields[1].field]}
                        placeholder={props.rangeForm.rangeValue1Placeholder}
                        onChange={onFieldChanged}
                         />

                        <TextField className={classes.rangFormTextField} label={props.fields[2].label} 
                        type={props.fields[2].type}
                        name={props.fields[2].field}
                        //inputProps={{ min: valueLimits[props.fields[2].field].minValue, step: "1" }}
                        //error={fieldErrors[props.fields[2].field]&&fieldErrors[props.fields[2].field].length > 0}
                        //helperText={fieldErrors[props.fields[2].field]}
                        value={currentRange[props.fields[2].field]}
                        placeholder={props.rangeForm.rangeValue2Placeholder}
                        onChange={onFieldChanged}
                          />

                        <div className={classes.rangeFormActionsBox}>
                            <IconButton onClick={onCheckButtonTapped}>
                                <CheckIcon  />
                            </IconButton>
                            <IconButton onClick={onCloseRangeButtonTapped}>
                                <ClearIcon  />
                            </IconButton>
                        </div>

                </div>
            </Paper>
        )
    }

    let rangeItems = [];
    if(props.data){
        rangeItems = props.data.map((item) => {
            let itemClass = "range-list-item-light-box";
            if((props.data.indexOf(item) % 2) === 0 ){
                itemClass = "range-list-item-darker-box";
            }

            return  <RangeListItem fields={props.fields} item={item} className={itemClass} 
            key={props.data.indexOf(item)} 
            onDeleteButtonTap={onDeleteButtonTap}
             onEditButtonTap={()=>{
                onEditButtonTap(item)
             }} deleteDialogMessage={props.deleteDialogMessage} 
             deletingMessage={props.deletingMessage} 
             error={{
                 title: props.error.title,
                 closeBtnText: props.error.closeBtnText
             }}
             deleteItemCallback={onDeleteRange}
             />;
        });
    }
    
    //detectRangeGapsAndOverlaps();

    return (

        <Paper className={classes.root}>
            <div className={classes.header}>
                <p className={classes.title}>{props.title}</p>
                <IconButton onClick={onAddRangeButtonTapped} >
                    <AddBoxIcon />
                </IconButton>
            </div>
            <p className={classes.subtitle}>{props.subtitle}</p>
            {
                rangesInconsistent ?
                (
                    <div className={classes.warningInconsistencyBox}>
                        <WarningIcon />
                        <p>{props.inconsistencyMessage}</p>
                    </div>
                )
                :
                (<div></div>)
            }
            
            <div className={classes.mainBox}>
                {
                    rangeItems.length > 0 ?
                    (
                        <div className={classes.listBox}>
                            
                                 {rangeItems}
                            
                        </div>
                    )
                    :
                    (
                        <p>
                            {props.noDataMessage}
                        </p>
                    )
                }
            </div>
            
        </Paper>
        
    );
}


