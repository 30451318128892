import React,{useEffect,useState} from "react";
import {Card,CircularProgress,useMediaQuery} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    mainBox:{
        padding: "1em 1em 1em 1em",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
    },
    btn: {
        textTransform: "none"
    },
    loadingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      height:"100%"
    },
    loadingSpinner:{
      width:"2em!important",
      height:"2em!important"
    },
    dismissBtn:{
        textTransform:"none"
    },
    message:{
      margin:"0.6em 0.5em",
      fontSize:"1.2em"
    },
    errorBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      padding:"0.3em 0.5em",
      borderRadius:"0.3em",
      backgroundColor: red[50],
      color:red[900]
      },
      newPubBtn:{
        width:"15em",
        height:"5em",
        backgroundColor:"#4db6ac",
        color: "white",
        margin:"1em"
      },
      publicationsBtn:{
        width:"15em",
        height:"5em",
        backgroundColor:"#64b5f6",
        color: "white",
        margin:"1em"
      },
      titleBox:{
        padding: "1em",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
      },
      titleBoxIcon:{
          width:"5em",
          height:"5em",
          color:"#b0bec5",
          border:"1px solid #b0bec5",
          padding:"0.5em",
          borderRadius:"50%"
      },
      title: {
        fontFamily: "'Prompt', sans-serif",
        color:"#607d8b",
        fontSize:"2em"
      }
  }));

let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}


const AdminHomeView = (props)=> {
    const classes = useStyles();

    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);

    const history = useHistory();

    const theme = useTheme();

    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(()=>{
       // getUserProfile();
    },[]);

    if(loading){

        return (
            <div className={classes.loadingBox}>
                <CircularProgress className={classes.loadingSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.message}>Cargando perfil</p>
            </div>
        )
        
    }

    if(errorMessage){
        return (
            <div className={classes.errorBox}>
                <p className={classes.message}>{errorMessage}</p>
                <Button size="small" className={classes.dismissBtn} >Reintentar</Button>
            </div>
        )
    }

    
    const goToUsers = evt => {

      history.push("/app/users");
  }

  const goToPendingPublications = evt => {

      history.push("/app/publications-manager?state=pending");
  }

  return (
      <div className={classes.mainBox}>
          <div className={classes.titleBox}>
              <p className={classes.title}>Inicio</p>
              <HomeIcon className={classes.titleBoxIcon} />
          </div>
          <Button variant="contained" classes={{
              root:classes.newPubBtn
          }}
          onClick={goToUsers}
          >
              Ir a Usuarios
          </Button>

          <Button variant="contained" classes={{
              root:classes.publicationsBtn
          }}
          onClick={goToPendingPublications}
          >
            Ir a Revisiones
          </Button>
          
      </div>
      
    
    );

}

export default AdminHomeView;









