import React,{useEffect,useState} from "react";
import { useHistory } from "react-router";
import {CircularProgress,List,ListItem,FormControlLabel,Switch,useMediaQuery,Collapse} from '@material-ui/core';
import {fetchGet,fetchPost} from "./AuthenticatedFetch";
import Button from '@material-ui/core/Button';
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HelpIcon from '@material-ui/icons/Help';
import CheckIcon from '@material-ui/icons/Check';
import PersonIcon from '@material-ui/icons/Person';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorMessageWidget from "./ErrorMessageWidget";
import EditableListItemWidget from "./editable-list-item-widget";
import EditableInput from "./EditableInput";
import AsyncBoxWidget from "./AsyncBoxWidget";

const useStyles = makeStyles(theme => ({
    mainBox:{
        display:"flex",
        flex:"2",
        flexDirection:"column",
        alignItems:"flex-start",
        justifyContent:"center",
        fontFamily: "'Prompt', sans-serif"
    },
    btn: {
        textTransform: "none"
    },
    loadingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"column",
      width:"100%",
      flex:"2",
      fontFamily: "'Prompt', sans-serif",
    },
    loadingSpinner:{
      width:"2em!important",
      height:"2em!important"
    },
    message:{
      margin:"0.6em 0.5em",
      fontSize:"1.2em",
      
    },
    errorBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      flex:"2",
      fontFamily: "'Prompt', sans-serif"
      },
      newPubBtn:{
        width:"20em",
        height:"5em",
        backgroundColor:"#4db6ac",
        color: "white",
        margin:"1em"
      },
      usersBtn:{
        width:"20em",
        height:"5em",
        backgroundColor:"#64b5f6",
        color: "white",
        margin:"1em"
      },
      titleBox:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        width:"100%"
      },
      titleBoxIcon:{
          width:"1.2em",
          height:"1.2em",
          color:"#546e7a",
          marginRight:"0.5em"
      },
      title: {
        fontFamily: "'Prompt', sans-serif",
        color:"#546e7a",
        fontSize:"1.2em",
        margin:"0",
        flex:"2"
      },
      userCounterBox: {
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center"
      },
      userCounterText: {
        fontFamily: "'Prompt', sans-serif",
        color:"#4db6ac",
        fontSize:"1.2em",
        margin:"0",
        padding: "0.5em"
      },
      postCounterHelpIcon:{
            width:"0.8em",
            height:"0.8em",
            color:"#1565c0",
            marginLeft:"0.5em",
            boxShadow:"1px 1px 4px rgba(200,200,200,0.8)",
            borderRadius:"50%"
      },
      contentBox: {
        display:"flex",
        flexDirection:"column",
        width:"100%",
        flex:"2",
        alignItems:"center",
        justifyContent:"flex-start",
        padding: "0.5em",
        overflowY:"auto"
      },
      list:{
          width:"100%"
      },
      listItemCard:{
        flex:"2",
        padding:"0 0.5em 0.5em 0.5em",
        height:"100%"
      },
      itemDataBox:{
        display:"flex",
        flexDirection:"column",
        width:"100%",
        flex:"2",
        alignItems:"flex-start",
        justifyContent:"center"
      },
      itemDataState:{
          padding:"0.3em",
        borderRadius:"0.2em",
        color:"white",
        fontWeight:"bold"
      },
      itemDescription:{
        color:"#546e7a",
        fontSize:"1em",
        margin:"0.5em 0",
        flex:"2",
        wordBreak:"break-word"
      },
      itemInnerDataBox:{
        display:"flex",
        flexDirection:"column",
        width:"100%",
        flex:"2",
        alignItems:"center",
        justifyContent:"center",
        margin:"0.5em 0 1em 0",
        wordBreak:"break-all"
      },
      itemSecondDataBox:{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"flex-start"
      },
      itemPrice:{
          color:"#546e7a",
          fontWeight:"bold",
          fontSize:"1.3em",
          margin:"0 0.5em 0 0"
      },
      stateReasonBtn:{
          textTransform:"none",
          marginLeft:"0.5em",
          color:"#607d8b"
      },
      reasonMessageBox:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
      },
      reasonMessageText:{
        color:"#546e7a",
        fontSize:"1em",
        margin:"0.5em 0",
        flex:"2",
        wordBreak:"break-word",
        padding:"1em",
        margin:"1em 0",
        backgroundColor:"#ffecb3",
        borderRadius:"0.3em",
        textAlign:"justify"
      },
      reasonMessageActionsBox:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
      },
      reasonMsgAcceptBtn:{
        textTransform:"none",
        marginLeft:"0.5em",
      },
      reasonMsgCloseBtn:{
        textTransform:"none",
        margin:"1em",
      },
      reasonLoadingBox:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
      },
      reasonInnerBox:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        minHeight:"13em"
      },
      reasonSuccessIcon:{
        width:"0.8em",
        height:"0.8em",
        color:"#4caf50",
        margin:"0.5em 0"
    },
    reasonSuccessText:{
        color:"#4caf50",
        margin:"0.5em 0",
        textAlign:"center",
        wordBreak:"break-word"
    },
    listItem:{
      padding:"0.5em",
      width:"100%"
    },
    accordion:{
      width:"100%"
    },
    userNameLabel:{
      margin:"0",
      fontWeight:"bold"
    },
    accHeaderIcon:{
      color:"#bdbdbd",
      marginRight:"0.5em"
    },
    flexColumn:{
      display:"flex",
      width:"100%",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"flex-start"
    },
    flexRow:{
      display:"flex",
      width:"100%",
      alignItems:"center",
      justifyContent:"flex-start"
    },
    userPubsBtn:{
      textTransform:"none"
    },
    roundedBtn:{
      textTransform:"none",
      marginLeft:"0.5em",
      borderRadius:"2em",
      padding:"0.3em 1em"
    },
    bgPrimary:{
      backgroundColor:"#2196f3",
      color:"white"
    },
    bgDangerPrimary:{
      backgroundColor:"#ef5350",
      color:"white"
    }
  }));

let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}

const GreenSwitch = withStyles({
  switchBase: {
    
    '&$checked': {
      color: "#4caf50",
    },
    '&$checked + $track': {
      backgroundColor: "#4caf50",
    },
  },
  checked: {},
  track: {},
})(Switch);

const UsersView = (props)=> {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const [loading,setLoading] = useState(true);
    const [errorMessage,setErrorMessage] = useState(null);
    const [maxPubsAllowed,setMaxPubsAllowed] = useState(0);
    const [reasonMessageVisible,setReasonMessageVisible] = useState(false);
    const [users,setUsers] = useState([]);

    const maxPubsEditConfig = {
        placeholder:"400",
        label:"Máx. Publicaciones",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        loadingText:"Guardando",
        successText: "Listo!",
        dismissErrorText: "Ok"
    }

    const [usersConfigs,setUsersConfigs] = useState([]);

    const [publicationsPerUser,setPublicationsPerUser] = useState({});

    const history = useHistory();

    const getUsers = async ()=> {
        let response = await fetchGet(window.location.origin+"/api/users/all");
        
        if(response.ok){
            
            let result = await response.json();

            return result;
        }else{
            let error = await response.text();
            throw error;
        }
    }

    const getUsersConfigs = async ()=> {
      let response = await fetchGet(window.location.origin+"/api/users/allconfigs");
      
      if(response.ok){
          
          let result = await response.json();

          return result;
      }else{
          let error = await response.text();
          throw error;
      }
  }

  const getPublicationsCountPerUser = async ()=> {
    let response = await fetchGet(window.location.origin+"/api/shop/publicationsperuser");
    
    if(response.ok){
        
        let result = await response.json();

        return result;
    }else{
        let error = await response.text();
        throw error;
    }
  }

    const saveMaxPubsAllowed = async (userId,maxPubsAllowed) => {
        let response = await fetchPost(window.location.origin+"/api/usershop/userquotas",{
            user_id:userId,
            quotas:{
              max_pubs_allowed: maxPubsAllowed
            }
        });
       
        if(response.ok){
            let result = await response.text();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }

    const changeUserState = async (userId,disabled) => {
      let response = await fetchPost(window.location.origin+"/api/users/state",{
          user_id:userId,
          disabled:  disabled
      });
     
      if(response.ok){
          let result = await response.json();
          return result;
      }else{
          let result = await response.text();
          throw result;
      }

    }

    const sendDeletionRequest = async uid => {
        let response = await fetchPost(window.location.origin+"/api/users/delete",{
          user_id:uid
        });
       
        if(response.ok){
            let result = await response.json();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }

    const loadUsers = async () => {
        try{
            setLoading(true);
            setErrorMessage(null);
            let items = await getUsers();
            items.forEach((e,i) => {
                items[i].ui_extra = {
                  showActions: false
                }
            });
            setUsers(items);
            let configs = await getUsersConfigs();
            setUsersConfigs(configs);

            let pubsPerUser = await getPublicationsCountPerUser();
            console.log(pubsPerUser);
            setPublicationsPerUser(pubsPerUser);
            // let userQuota = await getUserQuotas();
            // setMaxPubsAllowed(userQuota.max_pubs_allowed);
            setLoading(false);
            return true;
        }catch(err){
            setLoading(false);
            setErrorMessage(err.toString());
            console.error(err);
        }
    }

    useEffect(()=> {
        loadUsers();
    },[]);

    if(loading){

        return (
            <div className={classes.loadingBox}>
                <CircularProgress className={classes.loadingSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.message}>Cargando Usuarios</p>
            </div>
        )
        
    }


    const reloadUsers = async () => {
        loadUsers();
    }

    if(errorMessage){
        return (
            <div className={classes.errorBox}>
                <ErrorMessageWidget 
                title="Ocurrió un error al consultar usuarios"
                message={errorMessage}
                okBtnText="Reintentar"
                onOkBtnClicked={reloadUsers}
                />

            </div>
        )
    }

    const toggleChecked = async (user,e) => {

      try{
       
        let ix = users.indexOf(user);

        let newUser = {
          ...user
        }

        newUser.disabled = !e.target.checked;

      let result = await changeUserState(newUser.uid,newUser.disabled);
        console.log("el result de change state");
        console.log(result);
      if(result && !result.started){

        console.log("el cambio no pudo hacerse debido a que ya se encuentra un proceso de habilitación o deshabilitación en curso. Espera a que termine el actual para intentar de nuevo.")
      }
      
       users.splice(ix,1,newUser);
       setUsers([...users]);
       
        return true;

      }catch(err){
        console.error(err);
        return false;
      }
      //setDevices(devices.slice());
      
  }

  const deleteUser = async (user,e) => {
   
        let ix = users.indexOf(user);

        changeItemUIState(users,user,{
          processing:true, processingMessage:"Eliminando usuario"
        },setUsers);

        await sendDeletionRequest(user.uid);
        //await timeout(1000);

        // let us = {
        //   ...user
        // }

        // us.deleting = true;

        // users.splice(ix,1,us);

        users.splice(ix,1);
        setUsers([...users]);

        return true;

  }

  const onConfirmMaxPubsValue = async (userId,newValue)=>{
    console.log(newValue);
    console.log(userId);

    await saveMaxPubsAllowed(userId,parseInt(newValue));

    if(usersConfigs[userId] && usersConfigs[userId].quotas){
      usersConfigs[userId].quotas.max_pubs_allowed = parseInt(newValue);
    }else{
      usersConfigs[userId] = {
        quotas:{
          max_pubs_allowed: parseInt(newValue)
        }
      }
    }
    
    setUsersConfigs({
      ...usersConfigs
    });

    //await timeout(2000);
   
    // nameEditConfig.value = newValue;
    // setNameConfig(nameEditConfig);
   
    return true;
  }

  const goToUserPubs = (user,e) => {
    history.push("/app/publications-manager?userId="+user.uid);
  }

  const changeItemUIState = (itemList,item,obj,setItems) => {
      let ix = itemList.indexOf(item);
      let newItem = {
          ...item
      }

      newItem.ui_extra = {
          ...item.ui_extra
      }

      if(obj){
          Object.keys(obj).forEach(e => {
            
              newItem.ui_extra[e] = obj[e];
          });
          
          itemList.splice(ix,1,newItem);
          setItems([...itemList]);
      }

      return newItem;
  }

  const showActions = (user,e) => {

      changeItemUIState(users,user,{
        showActions:!user.ui_extra.showActions
      },setUsers);
    
  }

  const showDeleteDialog = (user,e) => {

    changeItemUIState(users,user,{
      dialog:true,
      dialogTheme:"danger",
      dialogMessage:"¿Eliminar este usuario?"
    },setUsers);
  
  }

  const dismissDeleteDialog = (user,e) => {

    changeItemUIState(users,user,{
      dialog:false
    },setUsers);
  
  }

  const closeItemErrorDialog = (user,e) => {

    changeItemUIState(users,user,{
      errorMessage:""
    },setUsers);

    return true;
  }

    return (
        <div className={classes.mainBox}>
            <div className={classes.titleBox}>
                <PersonIcon className={classes.titleBoxIcon} />
                <p className={classes.title}>Usuarios</p>
                <div className={classes.userCounterBox}>
                    <p className={classes.userCounterText}>{users ? users.length : 0}</p>
                    {/* <Tooltip title="Indica la cantidad de publicaciones realizadas y el máximo permitido actualmente para tu cuenta." arrow>
                        <HelpIcon className={classes.postCounterHelpIcon} />
                    </Tooltip> */}
                </div>
            </div>
            <div className={classes.contentBox}>

                <List className={classes.list} style={{maxHeight: '100%', overflow: 'auto'}}>

                    {users.map((user) => (

                        <ListItem key={user.uid.toString()} className={classes.flexColumn} style={{
                          padding:"0"
                        }}>

                          <div className={classes.flexColumn} style={{
                            width:"100%"
                          }}>
                           

                            <div className={classes.flexRow}>
                              <PersonIcon className={classes.accHeaderIcon} />

                              <p className={classes.userNameLabel}>{user.displayName ? user.displayName : user.email}</p>

                            </div>
                            {
                              usersConfigs && usersConfigs[user.uid] && usersConfigs[user.uid].role === "admin" ? 
                              (
                                <div className={classes.flexRow} style={{
                                  justifyContent:"center"
                                }}>
                                  <p style={{
                                    padding:"0.3em",
                                    border: "1px solid rgba(200,200,200,0.5)",
                                    borderRadius:"0.3em",
                                  
                                    textAlign:"center"
                                  }}>{usersConfigs[user.uid].role}</p>
                                </div>
                              )
                              :
                              (
                                <div/>
                              )
                            }

                            <div className={classes.flexRow}>
                              <p style={{
                                flex:"2",
                                margin:"0",
                                textAlign:"center"
                              }}>{user.email}</p>
                            </div>

                            <div className={classes.flexRow} style={{justifyContent:"center", margin:"0.5em 0"}}>
                              <Button classes={{
                                root:`${classes.roundedBtn} ${classes.userPubsBtn}` 
                              }} size="small" variant="contained" onClick={e => goToUserPubs(user,e)}>Ver Publicaciones</Button>
                              
                              <Button variant="contained" classes={{
                                root: `${classes.roundedBtn} ${classes.bgPrimary}`
                              }} onClick={e => showActions(user,e)}> 
                                {user.ui_extra.showActions ? "Ocultar Acciones" : "Mostrar Acciones" }
                              </Button>

                            </div>

                            

                            <div className={classes.flexRow} style={{
                              justifyContent:"space-between"
                            }}>

                              {
                                publicationsPerUser[user.uid] ?
                                (
                                  <p style={{
                                    margin:"0"
                                  }}>
                                    {publicationsPerUser[user.uid].publicationsCount} / 
                                    {usersConfigs[user.uid] && usersConfigs[user.uid].quotas && 
                                    usersConfigs[user.uid].quotas.max_pubs_allowed ? " "+usersConfigs[user.uid].quotas.max_pubs_allowed: "0"  }
                                  </p>
                                )
                                :
                                (
                                  <p style={{
                                    margin:"0"
                                  }}>
                                    0 / 
                                    {usersConfigs[user.uid] && usersConfigs[user.uid].quotas && 
                                    usersConfigs[user.uid].quotas.max_pubs_allowed ? " "+usersConfigs[user.uid].quotas.max_pubs_allowed: " 0"  }
                                  </p>
                                )
                              }

                              <p style={{
                                color: user.disabled ? "#9e9e9e" : "#4caf50"
                              }}>{user.disabled ? "Deshabilitado" : "Habilitado"}</p>
                              

                            </div>

                          </div>
                          <Collapse in={user.ui_extra.showActions}>

                            <AsyncBoxWidget 
                            processing={user.ui_extra.processing}
                            success={user.ui_extra.success}
                            errorTitle={user.ui_extra.errorTitle}
                            dialog={user.ui_extra.dialog}
                            dialogTheme={user.ui_extra.dialogTheme}
                            dialogMessage={user.ui_extra.dialogMessage}
                            diagConfirmText="Eliminar"
                            diagCancelText="Cancelar"
                            onConfirmDialog={e => deleteUser(user,e)}
                            onCancelDialog={e => dismissDeleteDialog(user,e)}
                            successMessage="Listo!"
                            processingMessage={user.ui_extra.processingMessage}
                            errorMessage={user.ui_extra.errorMessage}
                            onDismissError={e => closeItemErrorDialog(user,e)}
                            className={classes.flexColumn} style={{
                                fontFamily: "'Prompt', sans-serif",
                                width:"100%"
                            }}>

                              <div className={smallScreen ? classes.flexColumn : classes.flexRow} style={{
                              justifyContent:"space-evenly"
                              }}>



                                <FormControlLabel
                                  control={<GreenSwitch 
                                      id={user.uid.toString()} checked={!user.disabled} onChange={e => toggleChecked(user, e) } color="primary" />}
                                  label={user.disabled ? "Deshabilitado" : "Habilitado"}
                                />

                                <EditableInput config={{
                                  ...maxPubsEditConfig,
                                  value: usersConfigs[user.uid] && usersConfigs[user.uid].quotas && 
                                  usersConfigs[user.uid].quotas.max_pubs_allowed ? usersConfigs[user.uid].quotas.max_pubs_allowed: 0 
                                }} onConfirm={newVal => onConfirmMaxPubsValue(user.uid,newVal)}/>

                                <Button variant="contained" classes={{
                                  root: `${classes.roundedBtn} ${classes.bgDangerPrimary}`
                                }} onClick={e => showDeleteDialog(user,e)}> 
                                  Eliminar
                                </Button>

                              </div>


                            </AsyncBoxWidget>
                            
                              {/* <EditableListItemWidget

                              className={classes.flexColumn}
                              deletable={true}
                              item={user}
                              title={""}
                              deletingMessage="Eliminando usuario"
                              errorDeleteTitle="Ocurrió un error al eliminar el usuario"
                              deleteMessage="¿Eliminar usuario?"
                              confirmBtnText="Eliminar"
                              cancelBtnText="Cancelar"
                              onConfirmDeletion={e => deleteUser(user,e)}

                              >


                              

                            </EditableListItemWidget> */}
                          </Collapse>

                           <hr style={{
                                width:"100%",
                                height: "1px",
                                backgroundColor:"#e0e0e0",
                                border: "none",
                                marginBottom:"1em"
                              }} />  
                        </ListItem>
                    
                        
                    ))}

                </List>
               
            </div>
           
            
        </div>
        
      
      );

}

export default UsersView;