import React,{useState} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button,CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { blue,red,green } from '@material-ui/core/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';


let mainWidth = "25em";

let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}

export default function EditableInput (props) {

  const xSmallScreen = useMediaQuery("screen and (min-width: 320px)");

  const medScreen = useMediaQuery("screen and (min-width: 600px)");

  

  if(xSmallScreen){
    mainWidth = "100%";
  }
  if(medScreen){
    mainWidth = "25em";
  }

  const useStyles = makeStyles(theme => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: mainWidth,
      height:"7em",
      margin:"1.5em 0"
    },
    editableBox:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        width: mainWidth,
    },
    nonEditableBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%"
    },
    editableActionsBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 2,
      width:"100%"
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    editBtn:{
      color: blue[500],
      textTransform: "none"
    },
    confirmEditBtn:{
      color: green[500],
      textTransform: "none"
    },
    cancelEditBtn:{
      color: red[500],
      textTransform: "none"
      },
    btn: {
        textTransform: "none"
    },
    loadingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      height:"7em",
      margin:"1.5em 0"
    },
    loadingSpinner:{
      width:"1.5em!important",
      height:"1.5em!important"
    },
    loadingText:{
      margin:"0.7em 0.5em"
    },
    successBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      color:green[500],
      height:"7em",
      margin:"1.5em 0"
    },
    message:{
      margin:"0.6em 0.5em"
    },
    errorBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      padding:"0.3em 0.5em",
      borderRadius:"0.3em",
      maxWidth: mainWidth,
      backgroundColor: red[50],
      color:red[900],
      height:"7em",
      margin:"1.5em 0"
      }
  }));

  const classes = useStyles();

  const [editable,setEditable] = useState(false);
  const [input,setInput] = useState(null);
  const [currentValue,setCurrentValue] = useState("");
  const [loading,setLoading] = useState(false);
  const [successMessage,setSuccessMessage] = useState(null);
  const [errorMessage,setErrorMessage] = useState(null);


  const edit = ()=>{
    setEditable(true);
    setCurrentValue(props.config.value);
    
  }

  const confirm = async ()=>{
      try{
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);
        //await timeout(2000);
        await props.onConfirm(currentValue);
        //throw "Error: aspasd asdmpaosd  paosmdpaosdp paomsdpaosdpoad";
        setLoading(false);
        setSuccessMessage(props.config.successText);
        await timeout(1000);
        setSuccessMessage(null);
        setEditable(false);
        return true;
      }catch(err){
        setLoading(false);
        setSuccessMessage(null);
        setErrorMessage(err.toString());
      }
  }

  const cancelEdit = ()=>{
    setEditable(false);
  }

  const dismissError = () => {
    setEditable(false);
    setErrorMessage(null);
  }

  const fieldChanged = (e) => {
    setCurrentValue(e.target.value);
  }


  if(loading){
    return (
        <div className={classes.loadingBox}>
            <CircularProgress className={classes.loadingSpinner} variant="indeterminate"></CircularProgress>
            <p className={classes.message}>{props.config.loadingText}</p>
        </div>
    )
  }

  if(errorMessage){
    return (
        <div className={classes.errorBox}>
            <p className={classes.message}>{errorMessage}</p>
            <Button size="small" className={classes.dismissBtn} onClick={dismissError}>{props.config.dismissErrorText}</Button>
        </div>
    )
  }

  if(successMessage){
    return (
        <div className={classes.successBox}>
            <CheckIcon />
            <p className={classes.message}>{props.config.successText}</p>
        </div>
    )
  }


  return (
    <div className={classes.root}>

         
      {
          editable ?
          (
            <div className={classes.editableBox}>
              {props.helperText}
                <TextField
                    type={props.config.type}
                    helperText={props.config.helperText}
                    label={props.config.label}
                    className={classes.input}
                    placeholder={props.config.placeholder || undefined}
                    value={currentValue}
                    onChange={fieldChanged}
                />
                
                <div className={classes.editableActionsBox}>
                    <Button size="small"  className={classes.confirmEditBtn} onClick={confirm}>{props.config.confirmText}</Button>
                    <Button size="small"  className={classes.cancelEditBtn} onClick={cancelEdit}>{props.config.cancelText}</Button>
                </div>
                
            </div>
          )
          :
          (
            <div className={classes.nonEditableBox}>
              
              <TextField
                    type={props.config.type}
                    readOnly={true}
                    helperText={props.config.helperText}
                    label={props.config.label}
                    className={classes.input}
                    placeholder={props.config.placeholder || undefined}
                    value={props.config.value}
                    
                />
                
                <Button size="small" className={classes.editBtn} onClick={edit}>{props.config.editText}</Button>
            </div>
            
          )
      }
    </div>
  );
}