

class FSRealTimeManager {
    
    constructor(authPost,firestore,col){
        this.firestore = firestore;
        this.topic = null;
        this.collection = col;
        this.userId = null;
        this.listener = null;
        this._callback = null;
        this.authPost = authPost;
    }

    onData( callback) {
        this._callback = callback;
    }

    async subscribeToNotifications(userId,topic){
        this.topic = topic;
        this.userId = userId;
        console.debug("subscribing this user to notifications from database ",{userId:userId});
        let segment = this.firestore.collection(this.collection)
        .doc(this.userId);

        let doc = await segment.get();


        if(!doc.exists){
            console.debug("Notification doc for this user does not exists... create one",{userId: userId});
            //;
            segment.set({});
        }

        segment = this.firestore.collection(this.collection)
        .doc(this.userId).collection(this.topic);

        let firstTime = true;

        this.listener = segment.onSnapshot( qSnap => {
            
            if(firstTime){

                if(qSnap.docs.length > 0){
                    this._callback(qSnap.docs,null);
                }

            }

            qSnap.docChanges().forEach( change => {
                
                if (!firstTime && change.type === "added") {
                    this._callback(qSnap.docs,null);
                }

                if (!firstTime && change.type === "removed") {
                    
                    if(qSnap.docs.length === 0){
                        //only return if there are no more items
                        this._callback(qSnap.docs,null);
                    }

                }

            });

            firstTime = false;

            // console.log("subscribed to "+topic);
            // this._callback(qSnap.docs,null);


        }, (error) => {
            this._callback(null,error);
            console.error(error);
        });

        return true;

    }


    async resetNotifications() {
        console.debug("sending reset notifications from FS request");
        await this.authPost("/api/userfsnotifications/reset");

        return true;
    }

    unsubscribeFromNotifications(){
        console.debug("unsubscribing notifications from FS");
        this.listener();
        return true;
    }

}

export default FSRealTimeManager;









