import React,{useEffect,useState} from "react";
import {CircularProgress,FormControlLabel,Button,Switch,IconButton} from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import ErrorMessageWidget from "./ErrorMessageWidget";
import {Edit,Delete} from '@material-ui/icons';
import {green,red,blueGrey} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    mainBox:{
        display:"flex",
        flex:"2",
        flexDirection:"column",
        alignItems:"flex-start",
        justifyContent:"center",
        fontFamily: "'Prompt', sans-serif"
    },
    loadingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"column",
      width:"100%",
      height:"100%",
      flex:"2",
      fontFamily: "'Prompt', sans-serif",
    },
    loadingSpinner:{
      width:"2em!important",
      height:"2em!important"
    },
    title: {
        flex:2
      },
      mainBox: {
          flex:"2",
          display:"flex",
          flexDirection:"column"
      },
      contentBox: {
          display:"flex",
          flex:2,
          flexDirection:"column",
          overflowY:"auto",
          color:"#616161",
          backgroundColor:"#e0e0e0"
      },
      itemBox: {
          display:"flex",
          flexDirection:"column",
          alignItems:"flex-start",
          justifyContent:"center",
          width:"100%",
      },
      itemHeader: {
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
          justifyContent:"flex-start",
          width:"100%"
      },
      itemInfo: {
          display:"flex",
          alignItems:"center",
          justifyContent:"flex-start",
          width:"100%"
      },
      itemTitle:{
          fontSize:"1.2em",
          flex:2,
          color:"#546e7a",
          wordBreak:"break-word"
      },
      itemInfoElem:{
          color:"#9e9e9e",
          margin:"0.5em 1em"
      },
      deleteIcon: {
          color: red[700]
      },
      deleteDialogBox: {
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
          justifyContent:"center",
          backgroundColor:red[50],
          width:"100%",
          height:"100%"
      },
      deleteDialogText: {
         margin:"1em 0"
      },
      confirmDeleteBtn: {
          color:red[50],
          backgroundColor: red[700],
          "&:hover": {
              //you want this to be the same as the backgroundColor above
              color:red[50],
              backgroundColor: red[700]
          },
          margin: '0 0.5em 0 0.5em',
          textTransform:"none"
      },
      btnBox:{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0.5em"
      },
      btn: {
          margin: '0 0.5em 0 0.5em',
          textTransform:"none"
      },
      spinner: {
          margin: '0.5em 0'
      },
      actionIconBtn:{
      },
      actionIconBox:{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width:"100%"
      },
      errorBox:{
          height:"100%"
      }
  }));

const GreenSwitch = withStyles({
    switchBase: {

        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

const EditableListItemWidget = (props)=> {
    const classes = useStyles();

    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);

    const [deleteItem,setDeleteItem] = useState(false);

    const [deleting,setDeleting] = useState(false);

    useEffect(()=>{
       // getUserProfile();
    },[]);

    const showDeleteDialog = e => {
        setDeleteItem(true);
    }

    const cancelDeletion = e => {
        console.log("canceldeletion");
        setDeleteItem(false);
        setErrorMessage(null);
        props.onCancelDeletion && props.onCancelDeletion();
    }

    const confirmDeletion = async e => {

        try{
            
            setDeleting(true);
            setDeleteItem(false);
            setErrorMessage(null);

            console.log("confirmdeletion");
            await props.onConfirmDeletion(props.item);

            setDeleting(false);

        }catch(err){
            setDeleting(false);
            setErrorMessage(err.toString());
            console.error(err);

        }
    
    }

    const editItem = e => {
        props.onEdit(props.item);
    }

    const toggleChecked = e => {
        console.log("cambio el toggle "+props.item.id+" "+ e.target.checked);

        props.onToggleChanged && props.onToggleChanged(e.target.checked)        
    }

    if(loading){

        return (
            <div className={classes.loadingBox}>
                <CircularProgress className={classes.loadingSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.message}>{props.loadingMessage}</p>
            </div>
        )
        
    }

    if(errorMessage){
        return (
            <ErrorMessageWidget 
                className={classes.errorBox}
                title={props.errorDeleteTitle}
                message={errorMessage}
                cancelBtnText="Cancelar"
                onCancelBtnClicked={cancelDeletion}
                />
        )
    }

    if(deleteItem){

        return (
            <div className={classes.deleteDialogBox}>
                <p className={classes.deleteDialogText}>{props.deleteMessage}</p>
                <div className={classes.btnBox}>
                    <Button classes={{
                        root: classes.confirmDeleteBtn
                    }} onClick={confirmDeletion}>{props.confirmBtnText}</Button>
                    <Button className={classes.btn} onClick={cancelDeletion}>{props.cancelBtnText}</Button>
                </div>

            </div>
        )

    }

    if(deleting){
        return (
            <div className={classes.deleteDialogBox}>
                <CircularProgress className={classes.spinner} />
                <p>{props.deletingMessage}</p>
            </div>
        )
    }


    return (
        <div className={classes.itemBox} >

            <div className={classes.itemHeader}>
                               
               
                <div className={classes.actionIconBox}>
                    {
                        props.withToggleBtn ? 
                        (
                            <FormControlLabel
                                control={<GreenSwitch 
                                    id={props.item.id.toString()} checked={props.item[props.active_field]} onChange={toggleChecked} color="primary" />}
                                label={props.activeLabel}
                            />
                        )
                        :

                        (
                            <div/>
                        )
                    }
                    {
                        props.editable ?
                        (
                            <IconButton className={classes.actionIconBtn} onClick={editItem} >
                                <Edit />
                            </IconButton>
                        )
                        :
                        (
                            <div/>
                        )
                    }

                    {
                        props.deletable ?
                        (
                            <IconButton className={classes.actionIconBtn} onClick={showDeleteDialog} >
                                <Delete className={classes.deleteIcon} />
                            </IconButton>
                        )
                        :
                        (
                            <div/>
                        )
                    }
                   
                    
                </div>

                <strong className={classes.itemTitle}>{props.title}</strong> 
            </div>
            {props.children}
        </div>
    );

}

export default EditableListItemWidget;