import React,{useState,useEffect} from "react";
import {fetchPost,fetchGet} from "./AuthenticatedFetch";
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import EditableInput from "./EditableInput";
import SettingsIcon from '@material-ui/icons/Settings';
import AsyncBoxWidget from "./AsyncBoxWidget";


let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}


const useStyles = makeStyles(theme => ({
    mainBox:{
        display:"flex",
        flex:"2",
        flexDirection:"column",
        alignItems:"flex-start",
        justifyContent:"center",
        fontFamily: "'Prompt', sans-serif"
    },
    titleBox:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        width:"100%",
        marginBottom:"1em"
      },
      titleBoxIcon:{
          width:"1.2em",
          height:"1.2em",
          color:"#546e7a",
          marginRight:"0.5em"
      },
      title: {
        fontFamily: "'Prompt', sans-serif",
        color:"#546e7a",
        fontSize:"1.2em",
        margin:"0",
        flex:"2"
      },
      flexColumn:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
    },
    flexRow:{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"flex-start"
    }
}));


const  AdminConfigView = () => {


    const classes = useStyles();
    const [config,setConfig] = useState(null);
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);

    const [storeURLConfig,setStoreURLConfig] = useState({
        label:"URL tienda",
        type:"string",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        loadingText:"Guardando",
        successText: "Guardado",
        dismissErrorText: "Ok",
        helperText:'URL de la tienda de prestashop',
        value:""
    });

    const [maxPubsAllowedConfig,setMaxPubsAllowedConfig] = useState({
        label:"Máxima cantidad de publicaciones (default)",
        type:"number",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        loadingText:"Guardando",
        successText: "Guardado",
        dismissErrorText: "Ok",
        helperText:'La cantidad máxima por defecto de publicaciones que un usuario puede hacer',
        value:0
    });

    const [maxDaysPubExpirationConfig,setMaxDaysPubExpirationConfig] = useState({
        label:"Expiración de una publicación (días)",
        type:"number",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        loadingText:"Guardando",
        successText: "Guardado",
        dismissErrorText: "Ok",
        helperText:'Máxima cantidad de días que una publicación debe permanecer en la tienda',
        value:0
    });

    useEffect(()=>{

        if(config){
            storeURLConfig.value = config.admin.store_url;
            maxPubsAllowedConfig.value = config.default_quotas.max_pubs_allowed;
            maxDaysPubExpirationConfig.value = config.admin.expirationProcess.max_days;

            setStoreURLConfig({
                ...storeURLConfig
            });

            setMaxPubsAllowedConfig({
                ...maxPubsAllowedConfig
            });

            setMaxDaysPubExpirationConfig({
                ...maxDaysPubExpirationConfig
            });

        }
        

    },[config]);

    const getAdminConfig = async ()=> {
        let response = await fetchGet(window.location.origin+"/api/shop/config");
       
        if(response.ok){
            let result = await response.json();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }


    const loadConfig = async () => {
        try{
            setLoading(true);
            setErrorMessage(null);

            let conf = await getAdminConfig();
            setConfig(conf);

            // await timeout(1500);

            // setConfig({
            //     admin:{
            //         store_url:"http://todotiendas.ar"
            //     },
            //     default_quotas:{
            //         max_pubs_allowed:100
            //     }
            // });
            
            setLoading(false);
            return true;

        }catch(err){
            setLoading(false);
            setErrorMessage(err.toString());
        }
    }

    useEffect(()=> {
        loadConfig()
    },[]);

    const saveConfig = async (config) => {
        let response = await fetchPost(window.location.origin+"/api/shop/config",config);
       
        if(response.ok){
            let result = await response.text();
            return result;
        }else{
            let result = await response.text();
            throw result;
        }
    }

    const onSaveStoreURL = async (newVal) => {
        let conf = config;
        conf.admin.store_url = newVal;
        await saveConfig(conf);
        storeURLConfig.value = newVal;
        setStoreURLConfig({
            ...storeURLConfig
        });
        return true;
    }

    const onSaveDefaultQuotas = async (newVal) => {
        let conf = config;
        conf.default_quotas.max_pubs_allowed = parseInt(newVal);
        await saveConfig(conf);
        maxPubsAllowedConfig.value = parseInt(newVal)
        setMaxPubsAllowedConfig({
            ...maxPubsAllowedConfig
        });
        return true;
    }

    const onSaveMaxDaysPubsQuotas = async (newVal) => {
        let conf = config;
        conf.admin.expirationProcess.max_days = parseInt(newVal);
        await saveConfig(conf);
        maxDaysPubExpirationConfig.value = parseInt(newVal)
        setMaxDaysPubExpirationConfig({
            ...maxDaysPubExpirationConfig
        });
        return true;
    }

    return (
        <div className={classes.mainBox}>
            <div className={classes.titleBox}>
                <SettingsIcon className={classes.titleBoxIcon}/>
                <p className={classes.title}>Configuración</p>
            </div>
            <Card className={classes.flexColumn} style={{
                flex:"2"
            }}>
                <AsyncBoxWidget 
                    processing={loading}
                    errorTitle="Error al consultar la configuración"
                    successMessage="Listo!"
                    processingMessage="Cargando"
                    errorMessage={errorMessage}
                    errorCancelBtnText="Reintentar"
                    onDismissError={loadConfig}
                    className={classes.flexColumn} style={{
                        fontFamily: "'Prompt', sans-serif",
                        width:"100%",
                        
                }}>
                
                    <div className={classes.flexColumn} >
                        <EditableInput config={storeURLConfig} onConfirm={onSaveStoreURL}/>

                        <EditableInput config={maxPubsAllowedConfig} onConfirm={onSaveDefaultQuotas}/>

                        <EditableInput config={maxDaysPubExpirationConfig} onConfirm={onSaveMaxDaysPubsQuotas}/>

                    </div>

                </AsyncBoxWidget>
            </Card>
        </div>
        
    );
}

export default AdminConfigView;