import React,{useEffect, useState} from "react";
import {Snackbar,useMediaQuery} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { useHistory} from "react-router-dom";
import {Info,Warning,Error} from "@material-ui/icons";
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    mainBox:{
        padding: "0.5em 0.5em 0.5em 0.5em",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
        backgroundColor:"#263238",
        borderRadius:"0.2em",
        boxShadow:"1px 1px 2px rgba(100,100,100,0.5)"

    },
    message:{
        margin:"0.5em",
        wordBreak:"all",
        color:"white"
    },
    actionBtn: {
        textTransform:"none",
        fontSize:"1.1em"
    },
    defaultBtn: {
        textTransform:"none",
        fontSize:"0.9em"
    }
    
  }));

  const useInfoStyles = makeStyles(theme => ({
    icon:{
        color:"#03a9f4",
        margin:"0 0.5em"
    },
    message:{
        margin:"0.5em",
        wordBreak:"all"
    },
    actionBtn:{
       color:"#4fc3f7",
    },
    defaultBtn:{
        color:"#fafafa",
    }
    
  }));

  const useWarningStyles = makeStyles(theme => ({
    icon:{
        color:"#ffc107",
        margin:"0 0.3em"
    },
    message:{
        margin:"0.3em",
        wordBreak:"all"
    },
    actionBtn:{
       color:"#ffc107",
    },
    defaultBtn:{
        color:"#fafafa",
    }
    
  }));

  const useDangerStyles = makeStyles(theme => ({
    icon:{
        color:"#e91e63",
        margin:"0 0.3em"
    },
    message:{
        margin:"0.5em",
        wordBreak:"all"
    },
    actionBtn:{
       color:"#e91e63",
    },
    defaultBtn:{
        color:"#fafafa",
    }
    
  }));

//   function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
//   }


const RealTimeNotificationWidget = (props)=> {
    const classes = useStyles();

    const theme = useTheme();

    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const classesTypes = {
        "info": useInfoStyles(),
        "warning": useWarningStyles(),
        "danger": useDangerStyles()
    }

    const [open,setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.show);
    },[props.show]);

    const handleClose = (event,reason) => {

        if (reason === 'clickaway') {
            return;
        }
      
        setOpen(false);
        console.log("snack cerrado");
        props.onClose();

    }

    const getIcon = () => {
        let node ;
        switch(props.type){
            case "warning": 
                node = (
                    <Warning className={classesTypes.warning.icon} />
                );
                break;
            case "danger": 
                node = (
                    <Error className={classesTypes.danger.icon} />
                );
                break; 
            
            default: node = (
                <Info className={classesTypes.info.icon} />
            );
        }

        return node;
    }

    return (
        <Snackbar open={open}
            autoHideDuration={3000} onClose={handleClose} 
            anchorOrigin={props.position ? props.position : {vertical:"bottom",horizontal:"right"}}>

            <div className={`${classes.mainBox} ${classesTypes[props.type ? props.type : "info"].mainBox}`}
                style={{
                    flexDirection: smallScreen ? "column" : "row"
                }}
            >
                {getIcon()}
                <p className={`${classes.message} ${classesTypes[props.type ? props.type : "info"].message}`}>{props.message}</p>
                <Button className={`${classes.actionBtn} ${classesTypes[props.type ? props.type : "info"].actionBtn}`} 
                    size="small" onClick={props.functionToCall}>
                    {props.actionMessage}
                </Button>

                <Button className={`${classes.defaultBtn} ${classesTypes[props.type ? props.type : "info"].defaultBtn}`} 
                    size="small" onClick={props.altActionToCall}>
                    {props.altActionMessage}
                </Button>
            </div>
            {/* <Alert onClose={handleClose} severity="success">
                This is a success message!
            </Alert> */}
        </Snackbar>
    );

}

export default RealTimeNotificationWidget;









