
import React,{useEffect,useState} from "react";
import "./AccountView.css";
import AccountPhoto from "./AccountPhoto";
import {Card,TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditableInput from "./EditableInput";
import { makeStyles } from '@material-ui/core/styles';
import { blue,red,green } from '@material-ui/core/colors';
import {CircularProgress } from '@material-ui/core';
import app from "./base";

const useStyles = makeStyles(theme => ({

    btn: {
        textTransform: "none"
    },
    loadingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      height:"100%"
    },
    loadingSpinner:{
      width:"2em!important",
      height:"2em!important"
    },
    dismissBtn:{
        textTransform:"none"
    },
    message:{
      margin:"0.6em 0.5em",
      fontSize:"1.2em"
    },
    errorBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      width:"100%",
      padding:"0.3em 0.5em",
      borderRadius:"0.3em",
      backgroundColor: red[50],
      color:red[900]
      }
  }));

let timeout =  millis => {
    let prom = new Promise((resolve,reject)=>{
        setTimeout(()=>{
            resolve();
        },millis);
    });
    return prom;
}

const updateProfile = async (updateObj)=>{
    try{
        await app.auth().currentUser.updateProfile(updateObj);
        return true;
    }catch(err){
        throw err;
    }
}


const AccountView = (props)=> {
    const classes = useStyles();

    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);
    const [currentUser,setCurrentUser] = useState(app.auth().currentUser);


    const nameEditConfig = {
        placeholder:"Tu nombre",
        label:"Tu nombre",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        loadingText:"Guardando",
        successText: "Listo!",
        dismissErrorText: "Ok",
        value:(currentUser.displayName || "")
    }

    const emailEditConfig = {
        placeholder:"Email",
        label:"Email",
        editText:"Editar",
        cancelText:"Cancelar",
        confirmText:"Guardar",
        loadingText:"Guardando",
        successText: "Listo!",
        dismissErrorText: "Ok",
        value:currentUser.email
    }

    const [nameConfig,setNameConfig] = useState(nameEditConfig);
    const [emailConfig,setEmailConfig] = useState(emailEditConfig);
    

    const getUserProfile = async ()=>{
        try{
            setLoading(true);
            setErrorMessage(null);
            
            //await timeout(2000);
            //throw "Error: akmdlska aksmdaksmdla laksmdlakmsdlkasdlakms lkamsdla laksmdlk masd";
            setLoading(false);
            setErrorMessage(null);
        }catch(err){
            setLoading(false);
            setErrorMessage(err.toString());
        }
    }



    const onConfirmNameValue = async (newValue)=>{
        console.log(newValue);
        await timeout(2000);
        await updateProfile({
            displayName: newValue,
            photoURL: currentUser.photoURL
        });
        setCurrentUser(app.auth().currentUser);
        nameEditConfig.value = currentUser.displayName;
        setNameConfig(nameEditConfig);
       
        return true;
    }

    const onConfirmEmailValue = async (newValue)=>{
        console.log(newValue);
        await timeout(2000);
        await app.auth().currentUser.updateEmail(newValue);
        setCurrentUser(app.auth().currentUser);
        emailEditConfig.value = currentUser.email;
        setEmailConfig(emailEditConfig);
       
        return true;
    }

    useEffect(()=>{
       // getUserProfile();
    },[]);

    


    if(loading){

        return (
            <div className={classes.loadingBox}>
                <CircularProgress className={classes.loadingSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.message}>Cargando perfil</p>
            </div>
        )
        
    }

    if(errorMessage){
        return (
            <div className={classes.errorBox}>
                <p className={classes.message}>{errorMessage}</p>
                <Button size="small" className={classes.dismissBtn} onClick={getUserProfile}>Reintentar</Button>
            </div>
        )
    }

    return (
        <div className="account-main-box">
            <h3>Mi cuenta</h3>
            <div className="account-data-box">
                <Card className="account-data-card">
                    <AccountPhoto className="acc-photo" photoURL={currentUser.photoURL}></AccountPhoto>
                    <div className="toolbar-box">
                        
                        <div className="user-data-box">
                            <EditableInput config={nameEditConfig} onConfirm={onConfirmNameValue}/>

                            <EditableInput config={emailEditConfig} onConfirm={onConfirmEmailValue}/>
                           
                        </div>
                    </div>
                
                </Card>
            </div>
            
        </div>
        
      
      );

}

export default AccountView;









