import React,{useState} from "react";
import {CircularProgress,Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorMessageWidget from "./ErrorMessageWidget";
import { Check } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    itemBox:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        flexDirection:"column",
        width:"100%",
        height:"100%",
        flex:"2",
    },
    dialogBox:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        width:"100%",
        height:"100%",
        minHeight:"15em",
        flex:"2",
    },
    processingBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"column",
      width:"100%",
      height:"100%",
      minHeight:"15em",
      flex:"2",
    },
    processingSpinner:{
      width:"2em!important",
      height:"2em!important"
    },
    errorBox:{
        height:"100%",
        width:"100%",
        minHeight:"15em"
    },
    flexColumn:{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start"
    },
    flexRow:{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"flex-start"
    },
    diagOKBtn:{
        backgroundColor:"#2196f3",
        color:"white",
        textTransform:"none",
        margin:"0 1em"
    },
    diagCancelBtn:{
        backgroundColor:"transparent",
        textTransform:"none",
        margin:"0 1em"
    },
    dangerPrimaryBtn:{
        color:"white",
        backgroundColor:"#ef5350",
        textTransform:"none",
        margin:"0 1em"
    },
    dangerPrimaryText:{
        color:"#ef5350"
    }
  }));

const AsyncBoxWidget = (props)=> {
    const classes = useStyles();

    const dismissError = e => {
        props.onDismissError && props.onDismissError();
    }

    if(props.processing){

        return (
            <div className={classes.processingBox}>
                <CircularProgress className={classes.processingSpinner} variant="indeterminate"></CircularProgress>
                <p className={classes.message}>{props.processingMessage}</p>
            </div>
        )
        
    }

    if(props.success){

        return (
            <div className={classes.dialogBox}>
                <Check style={{
                    color:"#4caf50",
                    width:"1.5em",
                    height:"1.5em",
                    margin:"0.5em"
                }} />
                <p className={classes.message}>{props.successMessage}</p>
            </div>
        )
        
    }

    if(props.errorMessage){
        return (
            <ErrorMessageWidget 
                className={classes.errorBox}
                title={props.errorTitle}
                message={props.errorMessage}
                cancelBtnText={props.errorCancelBtnText || "Cancelar"}
                onCancelBtnClicked={dismissError}
                />
        )
    }

    if(props.dialog){
        return (
            <div className={classes.dialogBox} >
                
                <p className={props.dialogTheme === "danger" ? `${classes.message} ${classes.dangerPrimaryText}` : classes.message}>{props.dialogMessage}</p>

                <div className={classes.flexRow}
                    style={{
                        margin:"1em",
                        justifyContent:"center"
                    }}
                >
                    {
                        props.diagConfirmText ?
                        (
                            <Button variant="contained" color="primary" classes={{
                                root: props.dialogTheme === "danger" ? classes.dangerPrimaryBtn : classes.diagOKBtn
                            }} onClick={props.onConfirmDialog}>{props.diagConfirmText}</Button>
                        )
                        :
                        (<div/>)
                    }

                    {
                        props.diagCancelText ?
                        (
                            <Button variant="contained" classes={{
                                root:classes.diagCancelBtn
                            }} onClick={props.onCancelDialog}>{props.diagCancelText}</Button>
                        )
                        :
                        (<div/>)
                    }
                </div>

            </div>
        )
    }

    return (
        <div className={classes.itemBox} >
            {props.children}
        </div>
    );

}

export default AsyncBoxWidget;