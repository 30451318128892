import React from "react";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

      mainBox:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        padding:"1em 1em",
        borderRadius:"0.3em",
        backgroundColor: "#ffebee",
        color:"#b71c1c",
        textAlign:"center",
        width:"100%"
      },
      errorMessage:{
        margin:"1em 0",
        maxHeight: "5em",
        overflowY: "auto"
      },
      errorBoxTitle:{
          margin:"0.5em",
          fontSize:"1em",
          color:"#37474f"
      },
      mainBtn:{
        color:"white",
        backgroundColor:"#b71c1c",
        textTransform:"none",
        margin:"0 1em"
      },
      secBtn:{
        color:"#37474f",
        backgroundColor:"transparent",
        textTransform:"none",
        margin:"0 1em"
      },
      btnBox:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"row",
        marginTop:"1em",

      }
  }));

const ErrorMessageWidget = (props)=> {
    const classes = useStyles();

    return (
      <div className={classes.mainBox}>
          <p className={classes.errorBoxTitle}>{props.title}</p>
          <p className={classes.errorMessage}>{props.message}</p>
          <div className={classes.btnBox}>
            {
              props.okBtnText ?
              (
                <Button size="small" variant="contained" classes={{
                  root: classes.mainBtn
                }}
                onClick={props.onOkBtnClicked}
                >{props.okBtnText}</Button>
              )
              :
              (
                <div/>
              )
            }

            {
              props.cancelBtnText ?
              (
                <Button size="small" classes={{
                  root: classes.secBtn
                }}
                onClick={props.onCancelBtnClicked}
                >{props.cancelBtnText}</Button>
              )
              :
              (
                <div/>
              )
            }
          </div>
          
          
      </div>
  );
}

export default ErrorMessageWidget;