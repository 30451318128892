import React, { useCallback,useState } from "react";
import { withRouter } from "react-router";
import CheckIcon from '@material-ui/icons/Check';
import app from "./base";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CircularProgress,TextField } from '@material-ui/core';
import "./SignUp.css";

let timeout =  millis => {
  let prom = new Promise((resolve,reject)=>{
      setTimeout(()=>{
          resolve();
      },millis);
  });
  return prom;
}

const useStyles = makeStyles(theme => ({
  brandTitle: {
    fontFamily: "'Prompt', sans-serif",
    fontSize:"3em"
  }
}));


const SignUp = ({ history }) => {
  
  const classes = useStyles();

  const [loading,setLoading] = useState(false);
  const [successMessage,setSuccessMessage] = useState(null);
  const [errorMessage,setErrorMessage] = useState(null);

  const handleSignUp = useCallback(async event => {
    event.preventDefault();
    setLoading(true);
    const { email, password } = event.target.elements;
    try {
      setSuccessMessage(null);
      setErrorMessage(null);
      setLoading(true);
      await app.auth().createUserWithEmailAndPassword(email.value, password.value);

      //await timeout(2000);
      setLoading(false);
      setSuccessMessage("Listo! Tu cuenta se creó con éxito. Redirigiendo...");
      await timeout(2000);
      
      setSuccessMessage(null);
      history.push("/");
      return true;
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.toString());

    }
  }, [history]);


  if (loading) {
    return (
        <div className="main-box">
            <CircularProgress variant="indeterminate"></CircularProgress>
            <p>Creando la cuenta...</p>
        </div>

    )
    
  }

  if(successMessage){
        return (

          <div className="main-signup-box">
             <CheckIcon className="success-icon" />
            
            <p className="success-text">{successMessage}</p>
            
          </div>
        
      );
  }

  const resetAll = ()=>{
    if(errorMessage){
      setErrorMessage(null);
    }
  }


  return (

      <div className="main-signup-box">
        <h1 className={classes.brandTitle}>TodoTiendas</h1>
         <h2>Crear una cuenta</h2>
        {
          errorMessage ?
          (
            <div className="error-text-box">
                <p className="error-text centered">Ocurrió un error al intentar crear tu cuenta:</p>
                <p className="error-text justified">{errorMessage}</p>
            </div>
          )
          :
          (
            <div></div>
          )

        }

       
        <form onSubmit={handleSignUp} className="signup-form">
            
            <TextField className="signup-form-control" label="Email" name="email" required type="email" 
            placeholder="usuario@gmail.com" 
            
            />

            <TextField className="signup-form-control" label="Nombre" name="firstname" type="text" 
            placeholder="ej. Francisco" 
            
            />

            <TextField className="signup-form-control" label="Contraseña" name="password" required type="password" 
            
            
            />
            
            <Button variant="contained" className="submit-btn" type="submit">Crear cuenta</Button>

            <p className="note">Al crear la cuenta, aceptas los <a href="">términos del servicio</a> y la <a href="">política de privacidad</a></p>
        </form>
      </div>


    
  );
};

export default withRouter(SignUp);