import React from "react";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Info,Warning,Cancel } from '@material-ui/icons';

const baseStyle = {

    mainBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"column",
      padding:"1em 1em",
      borderRadius:"0.3em",
      textAlign:"center",
      width:"100%"
    },
    icon:{
        height:"1.5em",
        width:"1.5em",
        marginBottom:"1em"
    },
    message:{
      margin:"1em 0",
      maxHeight: "5em",
      overflowY: "auto"
    },
    boxTitle:{
        margin:"0.5em",
        fontSize:"1em",
        fontWeight:"bold"
    },
    mainBtn:{
      textTransform:"none",
      margin:"0 1em",
      borderRadius:"2em"
    },
    secBtn:{
      textTransform:"none",
      margin:"0 1em",
      borderRadius:"2em"
    },
    btnBox:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"row",
      marginTop:"1em",
    }
}


  const useInfoStyles = makeStyles(theme => ({

    mainBox:{
      ...baseStyle.mainBox,
      backgroundColor: "#e1f5fe",
      color:"#455a64",
    },
    icon:{
        ...baseStyle.icon,
        color:"#039be5",
    },
    message:{
        ...baseStyle.message,
    },
    boxTitle:{
        ...baseStyle.boxTitle,
        color:"#455a64"
    },
    mainBtn:{
      ...baseStyle.mainBtn,
      color:"white",
      backgroundColor:"#039be5"
    },
    secBtn:{
      ...baseStyle.secBtn,
      color:"#455a64",
      backgroundColor:"transparent",
    }
}));

const useWarningStyles = makeStyles(theme => ({

    mainBox:{
      ...baseStyle.mainBox,
      backgroundColor: "#ffe0b2",
      color:"#455a64",
    },
    icon:{
        ...baseStyle.icon,
        color:"#ffc107",
    },
    message:{
        ...baseStyle.message,
    },
    boxTitle:{
        ...baseStyle.boxTitle,
        color:"#455a64"
    },
    mainBtn:{
      ...baseStyle.mainBtn,
      color:"white",
      backgroundColor:"#ff9800"
    },
    secBtn:{
      ...baseStyle.secBtn,
      color:"#455a64",
      backgroundColor:"transparent",
    }
}));

const useDangerStyles = makeStyles(theme => ({

    mainBox:{
      ...baseStyle.mainBox,
      backgroundColor: "#ffcdd2",
      color:"#455a64",
    },
    icon:{
        ...baseStyle.icon,
        color:"#f44336",
    },
    message:{
        ...baseStyle.message,
    },
    boxTitle:{
        ...baseStyle.boxTitle,
        color:"#455a64"
    },
    mainBtn:{
      ...baseStyle.mainBtn,
      color:"white",
      backgroundColor:"#f44336"
    },
    secBtn:{
      ...baseStyle.secBtn,
      color:"#455a64",
      backgroundColor:"transparent",
    }
}));

const MessageWidget = (props)=> {

    let classes = null;

    const metaClasses = {
        "info": useInfoStyles(),
        "warning":useWarningStyles(),
        "danger": useDangerStyles()
    }

    const getIcon = type => {
        if(type === "info"){
            return (
                <Info className={metaClasses[props.type].icon} />
            )
        }

        if(type === "warning"){
            return (
                <Warning className={metaClasses[props.type].icon} />
            )
        }

        if(type === "danger"){
            return (
                <Cancel className={metaClasses[props.type].icon} />
            )
        }


        return (
            <Info className={metaClasses[props.type].icon} />
        )
    }

    return (
      <div className={metaClasses[props.type].mainBox} >
          {getIcon(props.type)}
          <p className={metaClasses[props.type].boxTitle}>{props.title}</p>
          <p className={metaClasses[props.type].message}>{props.message}</p>
          <div className={metaClasses[props.type].btnBox}>
            {
              props.okBtnText ?
              (
                <Button size="small" variant="contained" classes={{
                  root: metaClasses[props.type].mainBtn
                }}
                onClick={props.onOkBtnClicked}
                >{props.okBtnText}</Button>
              )
              :
              (
                <div/>
              )
            }

            {
              props.cancelBtnText ?
              (
                <Button size="small" classes={{
                  root: metaClasses[props.type].secBtn
                }}
                onClick={props.onCancelBtnClicked}
                >{props.cancelBtnText}</Button>
              )
              :
              (
                <div/>
              )
            }
          </div>
          
          
      </div>
  );
}

export default MessageWidget;