import React, { useEffect,useState } from "react";
import app from "./base";
import {fetchGet} from "./AuthenticatedFetch";

export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {
    
    const [loginState,setLoginState] = useState({
        currentUser: null,
        loading: true,
        userInfo:null
    });

    const getUserInfo = async ()=>{
        try{
            let response = await fetchGet(window.location.origin+"/api/users/info");
            if(response.ok){
                let result = await response.json();
                return result;

            }else{
                throw response;
            }

        }catch(err){
            throw err;
        }
    }

    useEffect(() => {
        app.auth().onAuthStateChanged((currUser)=>{
            if(currUser){
                getUserInfo().then(userInfo=>{
                    setLoginState({
                        currentUser:currUser,
                        loading:false,
                        userInfo: userInfo
                    });
                }).catch(err=>{
                    throw err;
                });
            }else{
                setLoginState({
                    currentUser:currUser,
                    loading:false,
                    userInfo: null
                });
            }
            
            
        });
    }, []);

    return (
        <AuthContext.Provider value = {{loginState}} >
            {children}

        </AuthContext.Provider>
    );


};